import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, mq, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';
import { ServiceSelection } from '@shared/service_selector/constants';
import { IValuePropCard, ValuePropCard } from '@shared/value_prop_card';

interface IMovingPreview {
  title: React.ReactNode;
  valueProps: IValuePropCard[];
}

const Container = styled.div`
  letter-spacing: initial;
  text-align: center;
  color: ${COLORS.tealDark};
  overflow-x: hidden;

  ${mq({
    padding: ['48px 0 0', '88px 0'],
  })}
`;

const FluidContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  ${mq({
    padding: ['0 24px', 0, '0 24px'],
  })}
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${mq({
    margin: ['20px auto', '80px auto 48px'],
    flexDirection: ['column', 'row'],
  })};

  & > div {
    ${mq({ padding: ['34px 24px', '8px'] })};
  }
`;

const MovingPreview: React.FC<IMovingPreview> = ({ title, valueProps }) => (
  <Container>
    <FluidContainer>
      <Text.Title size="large">{title}</Text.Title>
      <Content>
        {valueProps.map((valueProp, i) => (
          <ValuePropCard
            key={i}
            title={valueProp.title}
            description={valueProp.description}
            illustration={valueProp.illustration}
          />
        ))}
      </Content>
      <ButtonCTA
        container="moving_preview"
        entryPoint="service_page_button"
        label="Get a Quote"
        service={ServiceSelection.Moving}
      />
    </FluidContainer>
  </Container>
);

export { MovingPreview };
