import * as React from 'react';

import { IGuideImage } from '../guide_image';
import { IGuideStepProps } from '../types/guide_step';

import { ImageStep } from './image_step';

import badClear from '@images/www/guide/good_bad/bad/clear_items.png';
import goodClear from '@images/www/guide/good_bad/good/clear_items.png';

const CLEAR_STEP_IMAGES: IGuideImage[] = [
  {
    captionText: 'Clear off furniture',
    captionKind: 'Good',
    imagePath: goodClear,
  },
  {
    captionText: 'Don’t have items all over',
    captionKind: 'Bad',
    imagePath: badClear,
  },
];

const CLEAR_STEP_TITLE =
  'Clear miscellaneous items off countertops & furniture';
const CLEAR_STEP_DETAILS =
  'Gather up all the little items laying around on counters, tables, desks, and furniture.';

export const ClearStep: React.FC<IGuideStepProps> = (props) => (
  <ImageStep
    guideImages={CLEAR_STEP_IMAGES}
    title={CLEAR_STEP_TITLE}
    details={CLEAR_STEP_DETAILS}
    {...props}
  />
);
