import React from 'react';

import styled from '@emotion/styled';

const Link = styled.a`
  position: absolute;
  left: -999px;
  width: 1px;
  top: auto;
`;

export const SkipLink: React.FC<{ mainContentId: string }> = ({
  mainContentId,
}) => <Link href={`#${mainContentId}`}>Skip to content</Link>;
