import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

interface IAnimatedButtonTheme {
  textAfter: string;
  textBefore: string;
  bgAfter: string;
  bgBefore: string;
  fontSize: string;
  shadowColor: string;
  border: string;
  padding?: string;
}

export type Theme =
  | 'default'
  | 'teal'
  | 'tealDark'
  | 'login'
  | 'footer'
  | 'homepageRedesign'
  | 'homepageRedesignFooter';

const colorButtonTheme = (textDefault: string, backgroundDefault: string) => ({
  textBefore: textDefault,
  textAfter: backgroundDefault,
  bgBefore: textDefault,
  bgAfter: backgroundDefault,
  fontSize: '18px',
  shadowColor: 'rgba(10,61,58,0.10)',
  border: 'none',
  padding: '12px 32px',
});

const THEMES: { [theme in Theme]: IAnimatedButtonTheme } = {
  default: colorButtonTheme(COLORS.tealDark, 'white'),
  teal: colorButtonTheme('white', COLORS.tealBrand),
  tealDark: colorButtonTheme('white', COLORS.tealPrimary),
  homepageRedesign: colorButtonTheme(COLORS.tealBackground, COLORS.tealPrimary),
  login: {
    textBefore: COLORS.tealDark,
    textAfter: COLORS.tealBackground,
    bgBefore: COLORS.tealDark,
    bgAfter: COLORS.tealBackground,
    fontSize: '18px',
    shadowColor: 'none',
    border: '1px solid',
    padding: '6px 16px',
  },
  footer: {
    textBefore: 'white',
    textAfter: 'white',
    bgBefore: 'rgba(255,255,255,0.10)',
    bgAfter: COLORS.tealDark,
    fontSize: '16px',
    shadowColor: 'none',
    border: '1px solid',
    padding: '6px 16px',
  },
  homepageRedesignFooter: {
    textBefore: 'white',
    textAfter: 'white',
    bgBefore: 'rgba(255,255,255,0.10)',
    bgAfter: COLORS.tealPrimary,
    fontSize: '16px',
    shadowColor: 'none',
    border: '1px solid',
    padding: '6px 16px',
  },
};

const themeStyles =
  (variable: keyof IAnimatedButtonTheme) =>
  ({ themeName = 'default' }: { themeName: Theme }) =>
    THEMES[themeName][variable];

const AnimatedButton = styled.button<{ themeName: Theme }>`
  color: ${themeStyles('textBefore')};
  border: ${themeStyles('border')};
  box-shadow: 0 16px 32px 0 ${themeStyles('shadowColor')};
  display: inline-block;
  cursor: pointer;
  font-size: ${themeStyles('fontSize')};
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.2px;
  outline: none;
  padding: ${themeStyles('padding')};
  position: relative;
  text-align: center;
  transition-duration: 0.3s;
  transition-property: color, box-shadow, transform, width;
  background: ${themeStyles('bgAfter')};

  &:before,
  &:after {
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.3s;
  }

  &:before {
    background: ${themeStyles('bgAfter')};
    width: 100%;
  }

  &:after {
    background: ${themeStyles('bgBefore')};
    width: 0%;
  }

  &:hover,
  &:active {
    color: ${themeStyles('textAfter')};
    box-shadow: 0 8px 16px 0 ${themeStyles('shadowColor')};
    transform: scale(0.98);
  }

  &:hover:after,
  &:active:after {
    width: 100%;
  }
`;

const AnimatedButtonLink = AnimatedButton.withComponent('a');

export { AnimatedButton, AnimatedButtonLink };
