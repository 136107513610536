import * as React from 'react';

import { COLORS } from '@clutter/clean';
import { Stats } from '@shared/page_builder/components/stats';

const BusinessStats: React.FC<{}> = () => (
  <div>
    <Stats
      background={COLORS.dust}
      textColor={COLORS.tealDark}
      stats={[
        { value: '50,000', description: 'Customers served nationwide' },
        { value: '3.4 million', description: 'Items stored' },
        { value: '92%', description: 'Customer satisfaction' },
      ]}
    />
  </div>
);

export { BusinessStats };
