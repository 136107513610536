import React, { type ReactNode } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';

const MOBILE_GRID_TEMPLATE = `
"title"
"media"
"description"
`;

const DESKTOP_GRID_TEMPLATE = `
"media title"
"media description"
`;

const Body = styled(Text.Body)`
  a {
    color: ${COLORS.tealPrimary};

    &:hover {
      color: ${COLORS.__primaryButtonHover};
    }
  }
`;

export const BaseTemplate: React.FC<{
  heading: string;
  subheading: string;
  body: ReactNode;
  media: ReactNode;
  container: string;
}> = ({ heading, subheading, body, media, container }) => {
  return (
    <Box>
      <Box.Grid
        gridTemplateAreas={[MOBILE_GRID_TEMPLATE, null, DESKTOP_GRID_TEMPLATE]}
        gridTemplateColumns={['1fr', null, '1fr 1fr']}
      >
        <Box.GridItem
          gridArea="title"
          textAlign={['center', null, 'left']}
          margin={['0 0 24px', null, 'initial']}
        >
          <Box margin="0 0 8px">
            <Text.Title size="medium">{heading}</Text.Title>
          </Box>
          <Text.Headline weight={FontWeight.Medium}>{subheading}</Text.Headline>
        </Box.GridItem>
        <Box.GridItem
          margin={['32px 0 0', null, '24px 0 0']}
          gridArea="description"
        >
          <Body>{body}</Body>
          <Box margin="40px 0 0">
            <ButtonCTA
              container={container}
              label="Get Pricing"
              entryPoint="homepage_cta"
            />
          </Box>
        </Box.GridItem>
        <Box.GridItem gridArea="media" margin={['initial', null, '0 64px 0 0']}>
          {media}
        </Box.GridItem>
      </Box.Grid>
    </Box>
  );
};
