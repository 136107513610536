import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, mq, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';
import { CheckListItem } from '@shared/check_list_item';
import { ResponsiveImage } from '@shared/responsive_image';
import { ServiceSelection } from '@shared/service_selector/constants';
import { IResponsiveImage } from '@utils/images';

const Container = styled.div`
  color: ${COLORS.tealDark};
  position: relative;

  ${mq({
    textAlign: ['center', null, 'left'],
  })}
`;

const FluidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  overflow-x: hidden;

  ${mq({
    padding: ['64px 0', null, '60px 0 90px 60px'],
  })}
`;

const TitleContainer = styled.div`
  ${mq({
    width: ['100%', '660px'],
    margin: ['0 auto', null, 'auto 0 24px 5%'],
    padding: ['0 24px', 0],
  })}
`;

const Title = styled(Text.Title)`
  margin-bottom: 16px;
`;

const ValuePropsContainer = styled.div`
  ${mq({
    width: ['100%', '400px', '660px'],
    margin: ['0 auto', null, '0 0 auto 5%'],
    padding: ['0 24px', 0],
    textAlign: ['center', null, 'left'],
  })}
`;

const List = styled.ul`
  margin-bottom: 32px;
  text-align: left;

  ${mq({
    columnCount: [1, null, 2],
  })}
`;

const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;

  ${mq({
    height: ['216px', null, 'initial'],
    maxWidth: ['500px', null, 'initial'],
    margin: ['0 auto', null, 0],
  })}
`;

const MainImageContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;

  ${mq({
    position: [null, null, 'absolute'],
    width: ['227px', null, '270px', '316px'],
    height: ['163px', '145px', '180px', '210px'],
    left: [null, null, '752px', '806px'],
    top: [null, null, '-128px', '-108px'],
  })}
`;

const SecondaryImageContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;

  ${mq({
    position: [null, null, 'absolute'],
    width: ['175px', null, '232px'],
    height: ['112px', null, '132px', '154px'],
    left: [null, null, '905px', '1138px'],
    right: ['-8px', 0],
    top: ['40px', null, '70px', '-16px'],
  })}
`;

const MainImage = styled(ResponsiveImage)`
  border-radius: 8px;
  display: block;
`;

const SecondaryImage = styled(ResponsiveImage)`
  border-radius: 8px;
  opacity: 0.7;
  display: block;
`;

const ProductOverview: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  mainImage: IResponsiveImage;
  secondaryImage: IResponsiveImage;
  valueProps: string[];
  service: ServiceSelection;
}> = ({ title, subtitle, mainImage, secondaryImage, valueProps, service }) => (
  <Container>
    <FluidContainer>
      <TitleContainer>
        <Title size="large">{title}</Title>
        <Text.Headline>{subtitle}</Text.Headline>
      </TitleContainer>
      <ImagesContainer>
        <MainImageContainer>
          <MainImage image={mainImage} alt="" />
        </MainImageContainer>
        <SecondaryImageContainer>
          <SecondaryImage image={secondaryImage} alt="" />
        </SecondaryImageContainer>
      </ImagesContainer>
      <ValuePropsContainer>
        <List>
          {valueProps.map((item, i) => (
            <CheckListItem key={i}>{item}</CheckListItem>
          ))}
        </List>
        <ButtonCTA
          testId="product_overview_button_cta"
          container="product_overview"
          entryPoint="services_cta"
          label="Get a Quote"
          service={service}
        />
      </ValuePropsContainer>
    </FluidContainer>
  </Container>
);

export { ProductOverview };
