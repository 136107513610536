import React, { type ReactNode } from 'react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, Text } from '@clutter/clean';
import { CustomLink } from '@shared/custom_link';

const StyledCustomLink = styled(CustomLink)`
  color: ${COLORS.cloud};

  &:hover {
    text-decoration: underline;
  }
`;

export const CityLink: React.FC<{
  to: string;
  children: ReactNode;
}> = ({ to, children }) => {
  return (
    <Text.Body weight={FontWeight.Medium}>
      <StyledCustomLink to={to}>{children}</StyledCustomLink>
    </Text.Body>
  );
};
