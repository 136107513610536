/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { mq, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';
import {
  ComparisonMatrix,
  Legend,
  ServiceFeature,
} from '@shared/comparison_matrix';
import { ServiceSelection } from '@shared/service_selector/constants';

interface IProductComparison {
  title: React.ReactNode;
  legend: Legend[];
  serviceFeatures: ServiceFeature[];
  service: ServiceSelection;
}

const Container = styled.div`
  text-align: center;
  background: white;

  ${mq({
    padding: ['48px 24px', null, '120px 24px'],
  })};
`;

const MatrixContainer = styled.div`
  max-width: 1000px;

  ${mq({ margin: ['20px 0', '40px auto'] })}
`;

const ProductComparison: React.FC<IProductComparison> = ({
  title,
  legend,
  serviceFeatures,
  service,
}) => {
  return (
    <Container>
      <Text.Title size="large">{title}</Text.Title>
      <MatrixContainer>
        <ComparisonMatrix legend={legend} serviceFeatures={serviceFeatures} />
      </MatrixContainer>
      <ButtonCTA
        container="product_comparison"
        entryPoint="service_page_button"
        label={
          service === ServiceSelection.Moving
            ? 'Build your quote'
            : 'Build your plan'
        }
        service={service}
      />
    </Container>
  );
};

export { ProductComparison };
