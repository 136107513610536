import * as React from 'react';

import { GuideFooter } from '../guide_footer';
import { GuideImage, IGuideImage } from '../guide_image';
import { StepContainer } from '../step_container';
import { IGuideStepProps } from '../types/guide_step';

export const ImageStep: React.FC<
  {
    guideImages: IGuideImage[];
    title: string;
    details: string;
  } & IGuideStepProps
> = ({ title, details, currentStep, totalSteps, guideImages, prev, next }) => (
  <StepContainer
    title={title}
    details={[details]}
    currentStep={currentStep}
    totalSteps={totalSteps}
    hasFooter
  >
    {guideImages.map((imageData, index) => (
      <GuideImage imageData={imageData} key={index} />
    ))}
    <GuideFooter onPrev={prev} onNext={next} />
  </StepContainer>
);
