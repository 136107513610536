import { useAbeetResult } from '@clutter/abeet';

type Version = 'player' | 'default';

export const useAbeetResultHero = (): Version => {
  const result = useAbeetResult('web_visitor_hero');
  const version: Version | undefined = result?.version as Version;

  return version ?? 'default';
};
