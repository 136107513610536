/* @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { COLORS, FontWeight, mq, Text } from '@clutter/clean';
import { Help__Article, Help__Category, Help__Section } from '@graphql/landing';
import { MOBILE_HEADER_HEIGHT_INT } from '@shared/header/header';
import { SKIP_SCROLL_TO_TOP_STATE } from '@shared/scroll_to_top';

import { Chevron } from '../helpers/chevron';
import { articleURL, sectionURL } from '../routes';

const Container = styled.div`
  ${mq({
    margin: ['0 -24px', '0'],
  })}

  &:first-of-type {
    ${mq({
      borderTop: [`1px solid ${COLORS.grayBorder}`, null, 'initial'],
    })}
  }

  > * {
    ${mq({
      borderBottom: [`1px solid ${COLORS.grayBorder}`, null, 'initial'],
    })}
  }
`;

const Section = styled(Link)`
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  position: sticky;
  background: ${COLORS.cloud};
  top: ${MOBILE_HEADER_HEIGHT_INT}px;

  ${mq({
    display: ['flex', null, 'none'],
  })}
`;

const Entries = styled.div`
  ${mq({
    background: [COLORS.grayBackground, null, 'initial'],
  })}
`;

const Entry = styled(Link)`
  display: block;
  color: ${COLORS.tealDark};

  &:hover {
    color: ${COLORS.tealPrimary};
  }

  ${mq({
    padding: ['32px 0', null, '20px 0'],
    margin: ['0 24px', null, '0'],
  })}

  &:not(:last-of-type) {
    ${mq({
      borderBottom: [`1px solid ${COLORS.grayBorder}`, null, 'initial'],
    })}
  }
`;

export const Articles: React.FC<{
  category: Pick<Help__Category, 'id'>;
  section: Pick<Help__Section, 'id' | 'name'> & {
    articles: ReadonlyArray<Pick<Help__Article, 'id' | 'title'>>;
  };
  active: boolean;
}> = ({ category, section, active }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(active);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    setExpanded(active);
  }, [active]);

  useEffect(() => {
    if (clicked && expanded && ref.current) {
      const top =
        ref.current.getBoundingClientRect().top +
        window.pageYOffset -
        MOBILE_HEADER_HEIGHT_INT;
      scrollTo({ top, behavior: 'smooth' });
    }
  }, [clicked, expanded]);

  return (
    <Container ref={ref}>
      <Section
        onClick={() => {
          setClicked(true);
          setExpanded(!expanded);
        }}
        to={{
          pathname: sectionURL(category.id, section.id),
          state: SKIP_SCROLL_TO_TOP_STATE,
        }}
      >
        <Text.Title size="extraSmall">{section.name}</Text.Title>
        <Chevron direction={expanded ? 'up' : 'down'} />
      </Section>
      {expanded && (
        <Entries>
          {section.articles.map((article) => (
            <Entry key={article.id} to={articleURL(article.id)}>
              <Text.Headline weight={FontWeight.Medium}>
                {article.title}
              </Text.Headline>
            </Entry>
          ))}
        </Entries>
      )}
    </Container>
  );
};
