import * as React from 'react';

import { ChangeData } from '@clutter/staircase';
import { useFlow } from '@shared/flow/flow_container';
import { transformKeysToSnakeCase } from '@utils/transform_keys_to_snake_case';

import { ClearStep } from './steps/clear_step';
import { GroupStep } from './steps/group_step';
import { GuideComplete } from './steps/guide_complete';
import { GuideLanding } from './steps/guide_landing';
import { HowClutterWorksStep } from './steps/how_clutter_works_step';
import { OrganizeStep } from './steps/organize_step';
import { PullStep } from './steps/pull_step';
import { IGuideData } from './types/guide_data';
import { GuideIncentive } from './types/guide_incentive';
import { AddedGuideProps, StepName } from './types/guide_step';

const GUIDE_STEPS = [
  { name: StepName.LANDING, step: GuideLanding },
  { name: StepName.CLEAR_ITEMS, step: ClearStep },
  { name: StepName.PULL_ITEMS, step: PullStep },
  { name: StepName.GROUP_ITEMS, step: GroupStep },
  { name: StepName.ORGANIZE_ITEMS, step: OrganizeStep },
  { name: StepName.HOW_CLUTTER_WORKS, step: HowClutterWorksStep },
  { name: StepName.COMPLETE, step: GuideComplete },
];

const INITIAL_VALUES: IGuideData = {
  organizationQuestions: {
    boxes: false,
    luggage: false,
    none: false,
    plasticBags: false,
    plasticBins: false,
    toteBags: false,
  },
};

const onStepChange = ({ currentStep: { name } }: ChangeData<IGuideData>) => {
  if (name === StepName.COMPLETE) {
    return { action: 'complete' };
  }
};

export const GuideFlow: React.FC<{
  accountId: string;
  guideIncentive: GuideIncentive;
}> = ({ accountId, guideIncentive }) => {
  const { currentStep, Step, getStepProps } = useFlow<
    IGuideData,
    AddedGuideProps
  >({
    stepTransitionConfig: {
      flowName: 'customer_preparation',
      flowVersion: '1',
      resourceType: 'Account',
      resourceToken: accountId,
      transform: (data) => transformKeysToSnakeCase(data, true),
    },
    initialValues: INITIAL_VALUES,
    wtConfig: {},
    onStepChange,
    steps: GUIDE_STEPS,
    skipStepTransition: accountId === '',
  });

  return (
    <Step
      key={currentStep}
      {...getStepProps({
        guideIncentive,
        currentStep,
        totalSteps: GUIDE_STEPS.length - 1,
      })}
    />
  );
};
