import * as React from 'react';

import { WTEventParams } from '@clutter/wt';

import { track } from '../../initializers/wt';

interface IWtClickProps {
  children: React.ReactElement<{ onClick: (...args: any[]) => any }>;
  params: WTEventParams;
}

const WtClick = (props: IWtClickProps) => {
  const { children, params } = props;
  const wrapOnClick = (...args: any[]) => {
    if (props.children.props.onClick) {
      props.children.props.onClick(...args);
    }
    track(params);
  };
  return React.cloneElement(children, { onClick: wrapOnClick });
};

export { WtClick };
