import React from 'react';

import { COLORS } from '@clutter/clean';

import { ISharedIconProps } from './shared_icon_props';

export const RoundCheckmark: React.FC<
  ISharedIconProps & { strokeWidth?: number }
> = ({
  width = '30',
  height = '30',
  strokeWidth = 3,
  className,
  style,
  fillColor = COLORS.tealPrimary,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    style={style}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cy={15} cx={15} r={15} fill={fillColor} />
    <path
      d="M9.35234 17.6742L13.0386 20.3783L19.8364 11.344"
      stroke="white"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
