import * as React from 'react';

import styled from '@emotion/styled';
import { head } from 'lodash-es';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';

import {
  Breadcrumbs,
  COLORS,
  FontWeight,
  mq,
  ResponsiveContainer,
  SkeletonLoader,
  Text,
} from '@clutter/clean';
import { useHelpCategoryQuery } from '@root/graphql/landing';

import { Articles } from './category/articles';
import { Sections } from './category/sections';
import { Search } from './helpers/search';
import { Footer } from './layout/footer';
import { FooterCategories } from './layout/footer_categories';
import { FooterSupport } from './layout/footer_support';
import { sectionBreadcrumbs } from './utils/breadcrumbs';
import { mainURL, sectionURL } from './routes';

const Container = styled.div`
  display: flex;

  ${mq({
    flexDirection: ['column', null, 'row'],
    margin: ['32px 0', null, '64px 0'],
  })}
`;

const Sidebar = styled.div`
  ${mq({
    display: ['none', null, 'block'],
    margin: ['initial', null, '0 64px 0 0'],
    width: ['initial', null, '320px'],
  })}
`;

const Content = styled.div`
  width: 100%;
  ${mq({
    margin: ['initial', null, '0 0 64px 0'],
  })}
`;

const Name = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.grayBorder};
  padding-bottom: 32px;

  ${mq({
    display: ['none', null, 'block'],
  })}
`;

const CategoryName = styled(Text.Body)`
  text-transform: uppercase;
  color: ${COLORS.storm};
`;

const SectionName = styled(Text.Title)`
  color: ${COLORS.panther};
`;

export const Category: React.FC = () => {
  const { categoryID, sectionID } = useParams<{
    categoryID: string;
    sectionID: string;
  }>();
  const { data } = useHelpCategoryQuery({
    variables: { id: categoryID },
  });
  const category = data?.category;
  const section = category?.sections.find(({ id }) => id === sectionID);

  if (category === null) return <Redirect to={mainURL()} />;

  if (category && !section) {
    const section = head(category.sections);
    const path = section ? sectionURL(category.id, section.id) : mainURL();
    return <Redirect to={path} />;
  }

  const title = ['Clutter | Help', category?.name, section?.name]
    .filter((component) => component !== undefined)
    .join(' - ');

  return (
    <div>
      <Helmet>
        <title>{title}</title>;
      </Helmet>
      <ResponsiveContainer>
        <Text.Title size="medium">We’re Here to Help</Text.Title>
        <Search />
        {section && category ? (
          <Breadcrumbs
            links={sectionBreadcrumbs({ ...section, category: category })}
          />
        ) : (
          <SkeletonLoader width="100%" height="20px" />
        )}
        <Container>
          <Sidebar>
            <Sections category={category} />
          </Sidebar>
          <Content>
            <Name>
              {section && category ? (
                <div>
                  <CategoryName weight={FontWeight.Medium}>
                    {category.name}
                  </CategoryName>
                  <SectionName size="large">{section.name}</SectionName>
                </div>
              ) : (
                <SkeletonLoader width="100%" height="78px" />
              )}
            </Name>
            {category?.sections.map((section) => (
              <Articles
                key={section.id}
                category={category}
                section={section}
                active={section.id === sectionID}
              />
            ))}
          </Content>
        </Container>
      </ResponsiveContainer>
      <Footer>
        <FooterCategories display={['none', null, 'block']} />
        <FooterSupport />
      </Footer>
    </div>
  );
};
