import * as React from 'react';

interface IBallLoaderProps {
  className?: string;
  style?: React.CSSProperties;
}

export const BallLoader: React.FC<IBallLoaderProps> = ({
  className,
  style,
}) => (
  <div className={`ball-loader ${className || ''}`} style={style}>
    <div />
    <div />
    <div />
  </div>
);
