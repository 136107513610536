import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { App as SharedApp } from '@root/root/shared/app';
import { Head } from '@shared/head';
import { SafeIDProvider } from '@shared/safe_id_context';
import { IServerData } from '@shared/server_data_context';

import { trackPageview } from '../../initializers/wt';
import { resolveHeadData } from '../../utilities/www/head_utilities';

import { Routes } from './routes';

const { useEffect, useState } = React;

interface IAppProps {
  serverData: IServerData;
  baseUrl: string;
}

const App: React.FunctionComponent<
  RouteComponentProps<{}> & Pick<IAppProps, 'baseUrl'>
> = ({
  location,
  history,
}: RouteComponentProps<{}> & Pick<IAppProps, 'baseUrl'>) => {
  const [head, setHeadData] = useState(resolveHeadData(location));

  useEffect(
    () =>
      // TODO: there seems to be a lag; the page changes before the listen cb is triggered.
      // Could be related to https://github.com/ReactTraining/react-router/issues/6525.
      history.listen((newLocation) => {
        // TODO: investigate a way to do this without timeout, may become less reliable
        // in future react versions
        setTimeout(() => {
          if (newLocation.hash) {
            const id = newLocation.hash.split('?')[0];
            const anchor = document.querySelector(id);
            if (anchor) {
              anchor.scrollIntoView();
            }
          }
        }, 0);
        trackPageview();
        setHeadData(resolveHeadData(newLocation));
      }),
    [],
  );

  return (
    <React.Fragment>
      <Head head={head} />
      <Routes />
    </React.Fragment>
  );
};

const AppWithRouter = withRouter(App);

const AppWithContexts: React.FunctionComponent<IAppProps> = ({
  serverData,
  baseUrl,
}) => (
  <SharedApp serverData={serverData}>
    <SafeIDProvider>
      <AppWithRouter baseUrl={baseUrl} />
    </SafeIDProvider>
  </SharedApp>
);

export { AppWithContexts as App };
