import React from 'react';

import { COLORS } from '@clutter/clean';

import { ISharedIconProps } from './shared_icon_props';

export const Checkmark: React.FC<ISharedIconProps> = ({
  width = '13',
  height = '12',
  className,
  fillColor = COLORS.tealDark,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81525 9.95187C5.25099 10.4317 4.40336 10.3934 3.88511 9.851L1.54787 7.40475C1.01042 6.84222 1.03074 5.95052 1.59326 5.41307C2.15578 4.87561 3.04749 4.89593 3.58494 5.45846L5.00507 6.94482L10.1196 2.20256C10.6901 1.67358 11.5814 1.70724 12.1103 2.27774C12.6393 2.84824 12.6057 3.73954 12.0352 4.26852L6.15989 9.71618C6.05452 9.81389 5.9382 9.89239 5.81525 9.95187Z"
      fill={fillColor}
    />
  </svg>
);
