import * as React from 'react';

import styled from '@emotion/styled';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import { COLORS, mq, toggleStyleValue } from '@clutter/clean';
import { Footer } from '@shared/content/footer';

import { Header } from '../shared/header/header';

import {
  CaliforniaPrivacyRights,
  LimitedSecurityPolicy,
  PrivacyPolicy,
  TermsOfUse,
} from './legal/documents';

interface ILegalTab {
  title: string;
  path: string;
}

const legalTabs: ILegalTab[] = [
  { title: 'Terms of Use', path: '/legal/tos' },
  { title: 'Privacy Policy', path: '/legal/privacy_policy' },
  {
    title: 'California Privacy Rights',
    path: '/legal/california_privacy_rights',
  },
  {
    title: 'Limited Security Warranty Policy',
    path: '/legal/limited_security_warranty',
  },
];

const Page = styled.div`
  background-color: ${COLORS.cloud};
`;

const Content = styled.div`
  display: flex;
  padding-left: 6.4vw;
  padding-right: 6.4vw;
  ${mq({
    flexWrap: ['wrap', null, 'nowrap'],
    paddingTop: ['96px', '160px'],
  })}
`;

const Sidebar = styled.div`
  ${mq({
    width: ['100%', null, 'initial'],
    marginRight: ['auto', '32px'],
    maxWidth: ['initial', '400px'],
  })}
`;

const LegalContent = styled.div`
  color: ${COLORS.panther};
  font-size: 16px;
  white-space: pre-wrap;
  width: 100%;
  ${mq({
    marginLeft: ['0', null, '30px'],
  })}
`;

const LegalTab = styled.button<{ isActive: boolean }>`
  padding-bottom: 24px;
  padding-top: 24px;
  border: none;
  border-bottom: ${COLORS.panther} 1px solid;
  font-family: 'Recoleta';
  font-size: 32px;
  cursor: pointer;
  transition: padding-left 0.2s ease;
  padding-left: ${toggleStyleValue('isActive', '20px', '0px')};
  color: ${toggleStyleValue('isActive', COLORS.tealJungle, COLORS.tealDark)};
  background: none;
  text-align: left;
  -webkit-appearance: none;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: none;
    padding-left: 20px;
    color: rgba(27, 144, 137, 0.6);
  }

  ${mq({
    width: ['100%', null, '350px'],
  })}
`;

const Legal = (props: RouteComponentProps<{}>) => (
  <Page>
    <Header />
    <Content>
      <Sidebar>
        {legalTabs.map(({ title, path }) => (
          <LegalTab
            key={title}
            isActive={props.location.pathname === path}
            onClick={() => props.history.push(path)}
          >
            {title}
          </LegalTab>
        ))}
      </Sidebar>
      <LegalContent>
        <Switch>
          <Route exact={true} path="/legal/tos" component={TermsOfUse} />
          <Route
            exact={true}
            path="/legal/privacy_policy"
            component={PrivacyPolicy}
          />
          <Route
            exact={true}
            path="/legal/california_privacy_rights"
            component={CaliforniaPrivacyRights}
          />
          <Route
            exact={true}
            path="/legal/limited_security_warranty"
            component={LimitedSecurityPolicy}
          />
          <Redirect to="/legal/tos" />
        </Switch>
      </LegalContent>
    </Content>
    <Footer />
  </Page>
);

const LegalWithRouter = withRouter(Legal);

export { LegalWithRouter as Legal };
