import { BaseProps } from '@clutter/staircase';

import { GuideIncentive } from './guide_incentive';

export type AddedGuideProps = {
  guideIncentive: GuideIncentive;
  currentStep: number;
  totalSteps: number;
};

export type IGuideStepProps = BaseProps & AddedGuideProps;

export enum StepName {
  LANDING = 'landing',
  CLEAR_ITEMS = 'clear_items',
  PULL_ITEMS = 'pull_items',
  GROUP_ITEMS = 'group_items',
  ORGANIZE_ITEMS = 'organize_items',
  HOW_CLUTTER_WORKS = 'how_clutter_works',
  ORGANIZATION_QUESTIONNAIRE = 'organization_questionnaire',
  COMPLETE = 'complete',
}
