import React from 'react';

import { Box, Text } from '@clutter/clean';
import {
  Location__City,
  Location__Service,
  Location__State,
} from '@graphql/platform';
import { cityURL } from '@root/components/locations/routes';

import { CityLink } from './city_link';

const slugify = (service: Location__Service) => {
  switch (service) {
    case Location__Service.SmartStorage:
      return 'storage';
    case Location__Service.Moving:
      return 'moving';
    case Location__Service.SelfStorage:
      return 'self-storage';
  }
};

const StateLinks: React.FC<{
  service: Location__Service;
  state: Pick<Location__State, 'id' | 'slug' | 'name'> & {
    cities: ReadonlyArray<
      Pick<Location__City, 'id' | 'slug' | 'name' | 'services'>
    >;
  };
}> = ({ state, service }) => {
  const cities = state.cities
    .filter((city) => city.services.includes(service))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  if (!cities.length) return null;

  return (
    <Box.Flex gap="4px" flexDirection="column">
      <Text.Headline>{state.name}</Text.Headline>

      <Box.Flex gap="2px" flexDirection="column">
        {cities.map((city) => (
          <CityLink
            key={city.id}
            to={cityURL(state.slug, city.slug, slugify(service))}
          >
            {city.name}
          </CityLink>
        ))}
      </Box.Flex>
    </Box.Flex>
  );
};

export const LocationLinks: React.FC<{
  name?: string;
  states: ReadonlyArray<
    Pick<Location__State, 'id' | 'code' | 'name' | 'slug'> & {
      cities: ReadonlyArray<
        Pick<Location__City, 'id' | 'content' | 'name' | 'services' | 'slug'>
      >;
    }
  >;
  service: Location__Service;
}> = ({ name, states, service }) => (
  <Box.Flex gap="16px" flexDirection="column">
    <Text.Title size="medium">{name}</Text.Title>

    <Box.Flex gap="16px" flexDirection="column">
      {[...states]
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((state) => (
          <StateLinks state={state} key={state.id} service={service} />
        ))}
    </Box.Flex>
  </Box.Flex>
);
