import React from 'react';

export const Repeat: React.FC<{
  times: number;
  component: React.FC;
}> = ({ times, component }) => {
  const Component = component;
  return (
    <>
      {Array.from({ length: times }, (_, i) => i + 1).map((key) => (
        <Component key={key} />
      ))}
    </>
  );
};
