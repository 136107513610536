/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';

import { Link, Paragraph } from './formatters';

export const LimitedSecurityPolicy = () => (
  <React.Fragment>
    <Paragraph>
      Capitalized terms not defined in this Limited Security Warranty Policy
      have the meaning given to them in Clutter’s{' '}
      <Link to="/legal/tos">Terms of Use</Link> (the "Agreement"), which are
      incorporated herein by reference.
    </Paragraph>

    <Paragraph>
      Subject to the terms, limitations, exclusions, and conditions of this
      Limited Security Warranty Policy and the Agreement, and solely to the
      extent covered by Clutter’s insurance policy, Clutter will reimburse you
      for:
    </Paragraph>

    <Paragraph>
      Loss of or damage to your properly packed Stored Items that have been
      tampered with, lost or stolen while in Clutter’s possession, up to a total
      maximum of US $1.00 per pound in aggregate per User (the "Limited Security
      Warranty"); damage to your household caused by Clutter’s gross negligence
      or willful misconduct, up to a total maximum of US $1000 in aggregate per
      User;
    </Paragraph>
    <Paragraph>
      and/or damage to your personal effects caused by Clutter’s gross
      negligence or willful misconduct, up to a total maximum of US $1.00 per
      pound in aggregate per User (together with (i) and (ii), also part of the
      Limited Security Warranty). In order to be eligible for reimbursement
      under (ii) or (iii), you must timely notify Clutter of any applicable
      damage while Clutter personnel remain present during or after the
      performance of services. Clutter shall have the right to decrease or deny
      reimbursement to the extent that your or a third party’s negligence or
      willful act contributed to the claimed damage.
    </Paragraph>

    <Paragraph>
      Under no circumstances shall Clutter have any liability, or any
      responsibility to reimburse you, for any damages to any Stored Items that
      Clutter has not individually and specifically inspected, packed, sealed
      and photographed, including without limitation any items inside or in some
      way obscured by other items or packaging. Any such items are excluded from
      coverage.
    </Paragraph>

    <Paragraph>
      Notwithstanding anything to the contrary, Clutter shall have the right, in
      its sole discretion, to inspect and determine the value of any damage to a
      Stored Item, household, or personal effects, the value of a lost Stored
      Item, and whether a damaged Stored Item, household, or personal effect is
      capable of being repaired. You may also purchase additional protection,
      such that your protection is the greater of the legal minimum price per
      pound or the amount of additional protection purchased. You may purchase
      additional protection of either $1,000 or $2,500, in each case in
      aggregate per User. Please visit www.clutter.com or contact us at (800)
      805-4023 for more details, including current rates for additional
      protection, and/or to purchase additional protection. You are free later
      to cancel your protection plan. However, cancellation or failure to make
      payments each month means that your protection will revert to the default
      total maximum of US $1.00 per pound in aggregate per User. Once you have
      selected your protection level, you cannot upgrade or change your
      protection level, except you may cancel your additional protection at any
      time.
    </Paragraph>

    <Paragraph>
      Notwithstanding the foregoing, but subject to the terms, limitations,
      exclusions, and conditions of this Limited Security Warranty Policy and
      the Agreement, and solely to the extent covered by Clutter’s insurance
      policy, if you created a Clutter account prior to August 10, 2016, then
      Clutter will reimburse you for loss of or damage to your Stored Items
      properly packed in Storage Boxes that have been tampered with, lost or
      stolen while in Clutter’s possession, up to a total maximum of US
      $1,000.00 in aggregate per User (the “Prior Limited Security Warranty”).
      Customers who created Clutter accounts prior to August 10, 2016 are not
      eligible to purchase additional protection.
    </Paragraph>

    <Paragraph>
      If you purchase additional coverage and then at any point reach your
      maximum total claim amount, Clutter will immediately cancel any later
      scheduled monthly protection plan payments from you and default your
      Limited Security Warranty coverage down to a total maximum of US $1.00 per
      pound in aggregate.
    </Paragraph>

    <Paragraph>
      In order to be eligible for either Limited Security Warranty, you must:
      (i) have photographs of your Stored Items (in order to allow us to confirm
      the pre-packing condition of the Stored Items); (ii) pack your Stored
      Items into boxes (“Storage Boxes”); (iii) promptly examine your Storage
      Box and Stored Items when Clutter returns your Storage Boxes to you, and
      (iv) immediately notify the Clutter representative who return-delivered
      your Storage Box in writing of any loss of or damage to your Stored Items
      at the time of delivery within five (5) days of delivery; and (v) read and
      agree to abide by the terms of the Clutter Damage Claim Form (subsections
      (i) through (v) of this paragraph, collectively, the “Warranty
      Requirements”). The Clutter Damage Claim Form for customers with a maximum
      coverage of US $1.00 per pound in aggregate per User is available here.
      The Damage Claim Forms for customers who purchased $1,000 or $2,500 of
      additional protection is available at here or here respectively. For
      clarity, this Clutter Limited Security Warranty Policy shall not apply to
      any Stored Items shipped directly by you or on your behalf to Clutter.
    </Paragraph>

    <Paragraph>
      Upon receiving notice of lost or damaged Stored Items, Clutter will
      investigate the cause of loss or damage. If Clutter determines, in its
      sole discretion, that the loss or damage was due to unauthorized tampering
      with or access to the Stored Items contained within your Storage Box,
      Clutter will pay to you either (i) the cost of repair of the damaged
      Stored Items (up to the aggregate per-user limit expressly set forth
      herein and selected by you), or (ii) the current replacement fair market
      value of the lost or damaged Stored Items (up to the aggregate per-user
      limit expressly set forth herein and selected by you), whichever is less.
      Clutter shall be entitled to require proof of the initial purchase price,
      repair cost, and/or replacement value, and/or fair market value of any
      damaged or stolen Stored Items.
    </Paragraph>

    <Paragraph>
      You agree that Clutter shall have no liability for any loss of or damage
      to any Stored Items if you breach or have breached any provision of the
      Agreement, or if you fail or have failed to comply with any of the
      Warranty Requirements above, including without limitation, any failure to
      have photographs taken of any missing or damaged Stored Items prior to
      packing the Stored Items or failure to notify Clutter of any or lost or
      damaged Stored Items immediately upon delivery. The Limited Security
      Warranty is not insurance and should not be considered a replacement or
      stand-in for any kind of insurance. We strongly encourage you to purchase
      insurance for all of your Stored Items.
    </Paragraph>

    <Paragraph>
      The Limited Security Warranty does not apply to, and Clutter shall not in
      any event be liable for, any loss or damage that falls into the following
      categories: (i) loss or damage to the Stored Items which does not arise as
      a direct consequence of any breach of this Agreement by Clutter or any
      deliberate or grossly negligent act or omission on the part of Clutter or
      its contractors; (ii) loss of or damage to any Prohibited Stored Items (as
      defined in the Agreement); (iii) loss or damage to composite wood items,
      such as pressboard, particle board or ready-to-assemble furniture; (iv)
      loss or damage to the internal workings of electronic items; (v) missing
      hardware for items Clutter did not disassemble; (vi) loss or damage to
      items that were previously damaged or repaired; (vii) loss of business,
      sales, revenue, profits or anticipated savings; (viii) loss or damage
      which was not reasonably foreseeable at the date of entering into this
      Agreement, regardless as to how such loss or damage was caused; (ix) loss
      or damage due to emotional distress; or (x) consequential damages of any
      character; or (xi) loss of or damage to Stored Items in connection with
      any Force Majeure Event (as defined in the Agreement).
    </Paragraph>

    <Paragraph>
      You acknowledge and agree that the Limited Security Warranty set forth
      herein shall be your sole and exclusive remedy and Clutter’s total
      liability to you in connection with any lost, stolen, or damaged Stored
      Items.
    </Paragraph>
  </React.Fragment>
);
