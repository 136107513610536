import * as React from 'react';

import styled from '@emotion/styled';

const AspectRatioBox = styled.div<{ ratio: number }>`
  position: relative;
  padding-top: ${({ ratio }) => (ratio * 100).toFixed(5) + '%'};
`;

const AspectRatioInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AspectRatio: React.FunctionComponent<{
  ratio: number;
  className?: string;
  children?: React.ReactNode;
}> = ({ ratio, className, children }) => {
  return (
    <AspectRatioBox ratio={ratio} className={className}>
      <AspectRatioInner>{children}</AspectRatioInner>
    </AspectRatioBox>
  );
};

export { AspectRatio };
