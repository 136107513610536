/* @jsx jsx */
import * as React from 'react';

import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS, mq, transparentize } from '@clutter/clean';
import { AnimatedButtonLink } from '@shared/animated_button';
import { Footer } from '@shared/content/footer';
import { ResponsiveImage } from '@shared/responsive_image';

import { Header } from '../shared/header/header';

import { CompanyPanel } from './company/company_panel';
import { Card } from './shared/card';
import { Centered } from './shared/centered';
import { textCenter } from './shared/shared_styles';
import { BigText } from './shared/text';
import { IRM_JOBS_URL } from './careers';

import cash from '../../images/www/company/cash-payment-bills.svg';
import truck from '../../images/www/company/delivery-truck-cargo.svg';
import garbage from '../../images/www/company/garbage-bin-throw.svg';
import rent from '../../images/www/company/real-estate-sign-rent.svg';
import shipment from '../../images/www/company/shipment-give.svg';
import sofa from '../../images/www/company/sofa-double.svg';
import network from '../../images/www/company/user-network.svg';
import cart from '../../images/www/company/warehouse-cart-packages.svg';
import storage from '../../images/www/company/warehouse-storage.svg';
import warehouse from '../../images/www/company/warehouse.svg';
import headerImage from '@images_responsive/www/careers/warehouse.jpg';

const TopContainer = styled.div`
  width: 100%;
  background: ${COLORS.tealDark};
  position: relative;
  max-height: 900px;

  ${mq({
    height: ['575px', '100vh'],
  })}
`;

const HeaderImage = styled(ResponsiveImage)`
  object-fit: cover;
  object-position: center;
  width: 100%;

  height: 100%;
  position: absolute;
`;

const ctaStyles = css`
  margin: 0 auto;
`;

const OpenRoleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  ${mq({ margin: ['80px 0', '128px 0'] })}
`;

const Icons = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
  flex-wrap: wrap;
  ${mq({
    maxWidth: [null, '800px', 'initial'],
    marginBottom: ['-16px', '0'],
  })}
`;

const baseIconContainerStyles = css`
  text-align: center;
  font-family: Recoleta;
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 18px 0;
`;

const ProductIconContainer = styled.div`
  ${baseIconContainerStyles};
  ${mq({
    flexBasis: ['50%', '33.33%', '16.66%'],
  })};
  font-size: 30px;
  color: ${transparentize(COLORS.tealPrimary, 0.5)};
`;

const ProductIcon = styled.img`
  height: 78px;
`;

const CapitalIconContainer = styled.div`
  ${baseIconContainerStyles};
  ${mq({
    flexBasis: ['50%', null, '25%'],
    fontSize: ['18px', '30px'],
  })};
  color: ${COLORS.tealPrimary};
`;

const CapitalIcon = styled.img`
  ${mq({
    height: ['70px', '78px'],
  })};
`;

const IconLine = styled.div`
  width: 64px;
  height: 3px;
  margin: 0 auto;
  margin-bottom: 22px;
  margin-top: 16px;
  border-radius: 100%;
  background-color: ${transparentize(COLORS.tealDark, 0.2)};
`;

const IconText = styled.div`
  padding: 0 10px;
`;

const primaryTealColor = css`
  color: ${COLORS.tealPrimary};
`;

const Container = styled.div`
  background: ${COLORS.tealBackground};
`;

const Company: React.FC = () => {
  return (
    <Container>
      <Header opaque />
      <TopContainer>
        <HeaderImage
          alt="Clutter’s pristine warehouse, with large teal racks filled with neatly organized belongings"
          image={headerImage}
        />
      </TopContainer>
      <Card offset={-125}>
        <Centered>
          <BigText>
            We’re on a mission to make people’s lives convenient, so they can
            experience more of what they love.
          </BigText>
        </Centered>
      </Card>
      <CompanyPanel>
        <BigText css={textCenter}>
          With the power of one click, manage your belongings.
        </BigText>
        <Icons>
          <ProductIconContainer css={primaryTealColor}>
            <ProductIcon src={storage} alt="Store" />
            <IconLine />
            Store
          </ProductIconContainer>
          <ProductIconContainer css={primaryTealColor}>
            <ProductIcon src={cart} alt="Move" />
            <IconLine />
            Move
          </ProductIconContainer>
          <ProductIconContainer>
            <ProductIcon src={shipment} alt="Donate" />
            <IconLine />
            Donate
          </ProductIconContainer>
          <ProductIconContainer>
            <ProductIcon src={garbage} alt="Junk" />
            <IconLine />
            Junk
          </ProductIconContainer>
          <ProductIconContainer>
            <ProductIcon src={cash} alt="Sell" />
            <IconLine />
            Sell
          </ProductIconContainer>
          <ProductIconContainer>
            <ProductIcon src={rent} alt="Rent" />
            <IconLine />
            Rent
          </ProductIconContainer>
        </Icons>
      </CompanyPanel>
      <Card>
        <Centered>
          <BigText>
            This magic is made possible by our proprietary, end-to-end
            technology.
          </BigText>
        </Centered>
      </Card>
      <CompanyPanel>
        <BigText css={textCenter}>Our intelligent software manages…</BigText>
        <Icons>
          <CapitalIconContainer>
            <CapitalIcon src={network} alt="Team Network" />
            <IconLine />
            <IconText>500+ Team members</IconText>
          </CapitalIconContainer>
          <CapitalIconContainer>
            <CapitalIcon src={truck} alt="Truck" />
            <IconLine />
            <IconText>200+ Trucks</IconText>
          </CapitalIconContainer>
          <CapitalIconContainer>
            <CapitalIcon src={warehouse} alt="Warehouse" />
            <IconLine />
            <IconText>2 million sqft of warehouse</IconText>
          </CapitalIconContainer>
          <CapitalIconContainer>
            <CapitalIcon src={sofa} alt="Sofa" />
            <IconLine />
            <IconText>Millions of stored belongings</IconText>
          </CapitalIconContainer>
        </Icons>
      </CompanyPanel>
      <OpenRoleButtonContainer>
        <AnimatedButtonLink
          themeName="teal"
          href={IRM_JOBS_URL}
          target="_blank"
          css={ctaStyles}
        >
          BROWSE OPEN ROLES
        </AnimatedButtonLink>
      </OpenRoleButtonContainer>
      <Footer />
    </Container>
  );
};

export { Company };
