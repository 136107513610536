import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  DateTime: string;
};

export type Help__Article = {
  readonly __typename?: 'Help__Article';
  readonly body: Scalars['String'];
  readonly editedAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly section: Help__Section;
  readonly slug: Scalars['String'];
  readonly summary: Scalars['String'];
  readonly title: Scalars['String'];
};

export type Help__ArticleFilters = {
  readonly labels?: InputMaybe<ReadonlyArray<Help__Label>>;
};

export type Help__Category = {
  readonly __typename?: 'Help__Category';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly sections: ReadonlyArray<Help__Section>;
  readonly slug: Scalars['String'];
};

export enum Help__Label {
  Featured = 'FEATURED'
}

export type Help__PaginatedArticle = {
  readonly __typename?: 'Help__PaginatedArticle';
  readonly pagination: Pagination;
  readonly results: ReadonlyArray<Help__Article>;
};

export type Help__Section = {
  readonly __typename?: 'Help__Section';
  readonly articles: ReadonlyArray<Help__Article>;
  readonly category: Help__Category;
  readonly categoryID: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
};

export type Help__SectionFilters = {
  readonly labels?: InputMaybe<ReadonlyArray<Help__Label>>;
};

export type Pagination = {
  readonly __typename?: 'Pagination';
  readonly currentPage: Scalars['Int'];
  readonly totalCount: Scalars['Int'];
  readonly totalPages: Scalars['Int'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly article?: Maybe<Help__Article>;
  readonly articles: ReadonlyArray<Help__Article>;
  readonly categories: ReadonlyArray<Help__Category>;
  readonly category?: Maybe<Help__Category>;
  readonly search: Help__PaginatedArticle;
  readonly section: Help__Section;
  readonly sections: ReadonlyArray<Help__Section>;
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
};


export type QueryArticlesArgs = {
  filters: Help__ArticleFilters;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerySearchArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySectionArgs = {
  id: Scalars['ID'];
};


export type QuerySectionsArgs = {
  filters: Help__SectionFilters;
};

export type HelpArticleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HelpArticleQuery = { readonly __typename?: 'Query', readonly article?: { readonly __typename?: 'Help__Article', readonly id: string, readonly slug: string, readonly title: string, readonly body: string, readonly section: { readonly __typename?: 'Help__Section', readonly id: string, readonly name: string, readonly slug: string, readonly categoryID: string, readonly articles: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string }>, readonly category: { readonly __typename?: 'Help__Category', readonly id: string, readonly name: string, readonly sections: ReadonlyArray<{ readonly __typename?: 'Help__Section', readonly id: string, readonly name: string }> } } } | null };

export type HelpAutocompleteQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type HelpAutocompleteQuery = { readonly __typename?: 'Query', readonly autocomplete: { readonly __typename?: 'Help__PaginatedArticle', readonly results: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string, readonly summary: string, readonly section: { readonly __typename?: 'Help__Section', readonly id: string, readonly name: string } }> } };

export type HelpCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpCategoriesQuery = { readonly __typename?: 'Query', readonly categories: ReadonlyArray<{ readonly __typename?: 'Help__Category', readonly id: string, readonly name: string, readonly sections: ReadonlyArray<{ readonly __typename?: 'Help__Section', readonly id: string, readonly name: string }> }> };

export type HelpCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HelpCategoryQuery = { readonly __typename?: 'Query', readonly category?: { readonly __typename?: 'Help__Category', readonly id: string, readonly name: string, readonly sections: ReadonlyArray<{ readonly __typename?: 'Help__Section', readonly id: string, readonly name: string, readonly articles: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string }> }> } | null };

export type HelpRootQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpRootQuery = { readonly __typename?: 'Query', readonly sections: ReadonlyArray<{ readonly __typename?: 'Help__Section', readonly id: string, readonly categoryID: string, readonly name: string, readonly articles: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string }> }>, readonly articles: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string, readonly editedAt: string, readonly section: { readonly __typename?: 'Help__Section', readonly id: string, readonly name: string } }> };

export type HelpSearchQueryVariables = Exact<{
  query: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
}>;


export type HelpSearchQuery = { readonly __typename?: 'Query', readonly search: { readonly __typename?: 'Help__PaginatedArticle', readonly pagination: { readonly __typename?: 'Pagination', readonly totalPages: number, readonly currentPage: number, readonly totalCount: number }, readonly results: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string, readonly summary: string, readonly section: { readonly __typename?: 'Help__Section', readonly id: string, readonly name: string, readonly category: { readonly __typename?: 'Help__Category', readonly id: string, readonly name: string } } }> }, readonly articles: ReadonlyArray<{ readonly __typename?: 'Help__Article', readonly id: string, readonly title: string }> };


export const HelpArticleDocument = gql`
    query HelpArticle($id: ID!) {
  article(id: $id) {
    id
    slug
    title
    body
    section {
      id
      name
      slug
      categoryID
      articles {
        id
        title
      }
      category {
        id
        name
        sections {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useHelpArticleQuery__
 *
 * To run a query within a React component, call `useHelpArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHelpArticleQuery(baseOptions: Apollo.QueryHookOptions<HelpArticleQuery, HelpArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpArticleQuery, HelpArticleQueryVariables>(HelpArticleDocument, options);
      }
export function useHelpArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpArticleQuery, HelpArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpArticleQuery, HelpArticleQueryVariables>(HelpArticleDocument, options);
        }
export type HelpArticleQueryHookResult = ReturnType<typeof useHelpArticleQuery>;
export type HelpArticleLazyQueryHookResult = ReturnType<typeof useHelpArticleLazyQuery>;
export type HelpArticleQueryResult = Apollo.QueryResult<HelpArticleQuery, HelpArticleQueryVariables>;
export const HelpAutocompleteDocument = gql`
    query HelpAutocomplete($query: String!) {
  autocomplete: search(query: $query) {
    results {
      id
      title
      summary
      section {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useHelpAutocompleteQuery__
 *
 * To run a query within a React component, call `useHelpAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useHelpAutocompleteQuery(baseOptions: Apollo.QueryHookOptions<HelpAutocompleteQuery, HelpAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpAutocompleteQuery, HelpAutocompleteQueryVariables>(HelpAutocompleteDocument, options);
      }
export function useHelpAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpAutocompleteQuery, HelpAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpAutocompleteQuery, HelpAutocompleteQueryVariables>(HelpAutocompleteDocument, options);
        }
export type HelpAutocompleteQueryHookResult = ReturnType<typeof useHelpAutocompleteQuery>;
export type HelpAutocompleteLazyQueryHookResult = ReturnType<typeof useHelpAutocompleteLazyQuery>;
export type HelpAutocompleteQueryResult = Apollo.QueryResult<HelpAutocompleteQuery, HelpAutocompleteQueryVariables>;
export const HelpCategoriesDocument = gql`
    query HelpCategories {
  categories {
    id
    name
    sections {
      id
      name
    }
  }
}
    `;

/**
 * __useHelpCategoriesQuery__
 *
 * To run a query within a React component, call `useHelpCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<HelpCategoriesQuery, HelpCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpCategoriesQuery, HelpCategoriesQueryVariables>(HelpCategoriesDocument, options);
      }
export function useHelpCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpCategoriesQuery, HelpCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpCategoriesQuery, HelpCategoriesQueryVariables>(HelpCategoriesDocument, options);
        }
export type HelpCategoriesQueryHookResult = ReturnType<typeof useHelpCategoriesQuery>;
export type HelpCategoriesLazyQueryHookResult = ReturnType<typeof useHelpCategoriesLazyQuery>;
export type HelpCategoriesQueryResult = Apollo.QueryResult<HelpCategoriesQuery, HelpCategoriesQueryVariables>;
export const HelpCategoryDocument = gql`
    query HelpCategory($id: ID!) {
  category(id: $id) {
    id
    name
    sections {
      id
      name
      articles {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useHelpCategoryQuery__
 *
 * To run a query within a React component, call `useHelpCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHelpCategoryQuery(baseOptions: Apollo.QueryHookOptions<HelpCategoryQuery, HelpCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpCategoryQuery, HelpCategoryQueryVariables>(HelpCategoryDocument, options);
      }
export function useHelpCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpCategoryQuery, HelpCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpCategoryQuery, HelpCategoryQueryVariables>(HelpCategoryDocument, options);
        }
export type HelpCategoryQueryHookResult = ReturnType<typeof useHelpCategoryQuery>;
export type HelpCategoryLazyQueryHookResult = ReturnType<typeof useHelpCategoryLazyQuery>;
export type HelpCategoryQueryResult = Apollo.QueryResult<HelpCategoryQuery, HelpCategoryQueryVariables>;
export const HelpRootDocument = gql`
    query HelpRoot {
  sections(filters: {labels: [FEATURED]}) {
    id
    categoryID
    name
    articles {
      id
      title
    }
  }
  articles(filters: {labels: [FEATURED]}) {
    id
    title
    editedAt
    section {
      id
      name
    }
  }
}
    `;

/**
 * __useHelpRootQuery__
 *
 * To run a query within a React component, call `useHelpRootQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpRootQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpRootQuery(baseOptions?: Apollo.QueryHookOptions<HelpRootQuery, HelpRootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpRootQuery, HelpRootQueryVariables>(HelpRootDocument, options);
      }
export function useHelpRootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpRootQuery, HelpRootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpRootQuery, HelpRootQueryVariables>(HelpRootDocument, options);
        }
export type HelpRootQueryHookResult = ReturnType<typeof useHelpRootQuery>;
export type HelpRootLazyQueryHookResult = ReturnType<typeof useHelpRootLazyQuery>;
export type HelpRootQueryResult = Apollo.QueryResult<HelpRootQuery, HelpRootQueryVariables>;
export const HelpSearchDocument = gql`
    query HelpSearch($query: String!, $page: Int) {
  search(query: $query, page: $page) {
    pagination {
      totalPages
      currentPage
      totalCount
    }
    results {
      id
      title
      summary
      section {
        id
        name
        category {
          id
          name
        }
      }
    }
  }
  articles(filters: {labels: [FEATURED]}) {
    id
    title
  }
}
    `;

/**
 * __useHelpSearchQuery__
 *
 * To run a query within a React component, call `useHelpSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useHelpSearchQuery(baseOptions: Apollo.QueryHookOptions<HelpSearchQuery, HelpSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpSearchQuery, HelpSearchQueryVariables>(HelpSearchDocument, options);
      }
export function useHelpSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpSearchQuery, HelpSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpSearchQuery, HelpSearchQueryVariables>(HelpSearchDocument, options);
        }
export type HelpSearchQueryHookResult = ReturnType<typeof useHelpSearchQuery>;
export type HelpSearchLazyQueryHookResult = ReturnType<typeof useHelpSearchLazyQuery>;
export type HelpSearchQueryResult = Apollo.QueryResult<HelpSearchQuery, HelpSearchQueryVariables>;