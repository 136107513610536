import * as React from 'react';

import styled from '@emotion/styled';

import { Box, FontWeight, mq, SERIF_FONT_FAMILY, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';
import { ResponsiveImage } from '@shared/responsive_image';
import { ServiceSelection } from '@shared/service_selector/constants';
import { IResponsiveImage } from '@utils/images';

import star from '@images/icons/star.svg';

type Reviews = {
  name: string;
  quote: React.ReactNode;
  service: string;
  img?: IResponsiveImage;
};

const Container = styled.div`
  text-align: center;
  ${mq({
    padding: ['48px 24px', null, '80px 24px'],
  })};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${mq({ marginTop: ['48px', '64px'] })}
`;

const ReviewContainer = styled.div`
  padding: 24px;
  display: flex;
  text-align: left;
`;

const ContentContainer = styled.div<{ hasAvatar?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ hasAvatar }) => `margin-${hasAvatar ? 'left' : 'right'}: 16px;`}
  max-width: 460px;
`;

const Quote = styled(Text.Body)`
  position: relative;

  ${mq({
    margin: ['0 0 0 24px', null, '24px 0 0 24px'],
  })}
`;

const LeftQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  position: absolute;
  left: -24px;
  top: 12px;
  font-size: 40px;
`;

const RightQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  display: inline-block;
  font-size: 40px;
  line-height: 0;
  transform: translate(9px, 16px);
`;

const Stars = styled.div<{ imageSrc?: string }>`
  background-image: ${({ imageSrc }) =>
    imageSrc ? `url(${imageSrc})` : 'none'}
  }
  margin: 24px 0 8px;
  height: 24px;
  background-repeat: space no-repeat;
  width: 130px;
`;

const Avatar = styled(ResponsiveImage)`
  border-radius: 50%;

  ${mq({ width: ['80px', '108px'], height: ['80px', '108px'] })}
`;

const Name = styled(Text.Caption)`
  font-weight: ${FontWeight.Bold};
`;

const Review: React.FC<{ review: Reviews }> = ({ review }) => (
  <ReviewContainer>
    {review.img && (
      <Avatar image={review.img} baseImageSizes={[80, 160, 240, 324]} alt="" />
    )}
    <ContentContainer hasAvatar={!!review.img}>
      <Quote>
        <LeftQuote>“</LeftQuote>
        {review.quote}
        <RightQuote>”</RightQuote>
      </Quote>
      <Stars imageSrc={star} />
      <Name>{review.name}</Name>
      <Text.Caption>{review.service}</Text.Caption>
    </ContentContainer>
  </ReviewContainer>
);

const ProductReview: React.FC<{
  title: React.ReactNode;
  reviews: Reviews[];
  className?: string;
  showCTAButton?: boolean;
  service: ServiceSelection;
}> = ({ title, reviews, className, showCTAButton, service }) => {
  return (
    <Container className={className}>
      <Text.Title size="large">{title}</Text.Title>
      <InnerContainer>
        {reviews.map((review, i) => (
          <Review key={i} review={review} />
        ))}
      </InnerContainer>
      {showCTAButton && (
        <Box margin={[0, '24px 0 0']}>
          <ButtonCTA
            container="product_review"
            entryPoint="service_page_button"
            label="Get Started"
            service={service}
          />
        </Box>
      )}
    </Container>
  );
};

export { ProductReview };
