import styled from '@emotion/styled';

import { mq } from '@clutter/clean';

interface IResponsiveDisplay {
  mode: 'only' | 'not';
  display?: string;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}

export const ResponsiveDisplay = styled.span<IResponsiveDisplay>`
  ${({ mode, display = 'initial', sm, md, lg, xl }) =>
    mq({
      display: [sm, md, lg, xl]
        .map((val) => (mode === 'only' ? val : !val))
        .map((val) => (val ? display : 'none')),
    })}
`;
