import * as React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { IHeadData } from '../../resources/types/head_data';

interface IHeadProps {
  head: IHeadData;
}

class Head extends React.Component<IHeadProps> {
  constructor(props: IHeadProps) {
    super(props);
    // `global.helmetContext` is read by the server to insert the <title> and <meta> tags.
    global.helmetContext = {};
  }

  public render() {
    const {
      head: { title, meta, link },
    } = this.props;

    return (
      <HelmetProvider context={global.helmetContext}>
        <Helmet>
          <title>{`${title}`}</title>
          {this.tags('meta', meta)}
          {this.tags('link', link)}
        </Helmet>
      </HelmetProvider>
    );
  }

  private tags = (type: string, tagProps?: Array<React.HTMLProps<any>>) =>
    (tagProps || []).map((props, i) =>
      React.createElement(type, { ...props, key: i }),
    );
}

export { Head };
