import React from 'react';

import styled from '@emotion/styled';

import { Box, BREAKPOINTS, mq, Text } from '@clutter/clean';
import { ButtonCTA } from '@shared/button_cta';
import { Block, BlockProps } from '@shared/page_builder/block';
import { ServiceSelection } from '@shared/service_selector/constants';
import { ValuePropCard } from '@shared/value_prop_card';

import dottedSeparator from '@images/icons/dotted-separator.svg';

type ValueProp = {
  illustration: string;
  title: React.ReactNode;
  description: React.ReactNode;
};

const DottedSeparator = styled.img`
  position: absolute;
  width: 375px;
  max-width: unset;
  left: 50%;
  margin-left: -187.5px;
  bottom: 0;

  ${mq({ display: ['block', null, 'none'] })}
`;

const Content = styled.div<{ numItems: number }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  ${mq({
    margin: ['20px auto 0', null, '80px auto 0'],
    flexDirection: ['column', null, 'row'],
  })};

  & > div {
    @media (min-width: ${BREAKPOINTS.MD}) {
      flex-basis: ${({ numItems }) => (numItems % 2 === 0 ? '50%' : '33%')};
    }
  }
`;

export const IconValueProps: React.FC<
  {
    title: React.ReactNode;
    valueProps: ValueProp[] | ReadonlyArray<ValueProp>;
    cta?:
      | {
          service?: ServiceSelection;
          label?: string;
        }
      | true;
  } & BlockProps
> = ({ title, valueProps, cta, ...blockProps }) => {
  const { label, service } =
    cta === true ? { label: 'Get Started', service: undefined } : cta || {};
  return (
    <Block textAlign="center" {...blockProps}>
      <Text.Title size="large">{title}</Text.Title>
      <Content numItems={valueProps.length}>
        {valueProps.map((valueProp, i: number) => (
          <Box
            key={i}
            position="relative"
            maxWidth="480px"
            padding={['64px 24px', null, '8px']}
          >
            <ValuePropCard
              title={valueProp.title}
              description={valueProp.description}
              illustration={valueProp.illustration}
            />
            {i < valueProps.length - 1 && (
              <DottedSeparator src={dottedSeparator} alt="" loading="lazy" />
            )}
          </Box>
        ))}
      </Content>
      {cta && (
        <Box margin={['0', null, '60px 0 0']}>
          <ButtonCTA
            container="value_props"
            label={label || 'Get Started'}
            entryPoint="value_props"
            service={service}
          />
        </Box>
      )}
    </Block>
  );
};
