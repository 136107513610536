import * as React from 'react';

import { GuideFooter } from '../guide_footer';
import { StepContainer } from '../step_container';
import { IGuideStepProps } from '../types/guide_step';

const HOW_CLUTTER_WORKS_TITLE =
  'With your help, here’s how Clutter will work...';
const HOW_CLUTTER_WORKS_DETAILS = [
  'When things are organized, we can move quickly to help you store.',
  'We’ll bring additional supplies—including boxes, bubble wrap, packing paper, and moving blankets—and efficiently pack the items you’ve grouped together.',
  'As we go, we’ll take photos of your items to create your digital inventory online.',
  'We’ll also securely wrap your furniture and large items. When items are ready to go, we will load them on our truck to go into storage.',
];

export const HowClutterWorksStep: React.FC<IGuideStepProps> = ({
  currentStep,
  totalSteps,
  next,
  prev,
}) => (
  <StepContainer
    title={HOW_CLUTTER_WORKS_TITLE}
    currentStep={currentStep}
    totalSteps={totalSteps}
    details={HOW_CLUTTER_WORKS_DETAILS}
    hasFooter
  >
    <GuideFooter onNext={next} onPrev={prev} />
  </StepContainer>
);
