import * as React from 'react';

import { Helmet } from 'react-helmet-async';

import { ResponsiveContainer, Text } from '@clutter/clean';
import { useHelpRootQuery } from '@graphql/landing';

import { Search } from './helpers/search';
import { Footer } from './layout/footer';
import { FooterCategories } from './layout/footer_categories';
import { FooterSupport } from './layout/footer_support';
import { FooterTitle } from './layout/footer_title';
import { FeaturedArticles } from './root/featured_articles';
import { FeaturedSections } from './root/featured_sections';

export const Root: React.FC = () => {
  const { data } = useHelpRootQuery();

  return (
    <div>
      <Helmet>
        <title>Clutter | Help</title>
      </Helmet>
      <ResponsiveContainer>
        <Text.Title size="large">We’re Here to Help</Text.Title>
        <Search />
      </ResponsiveContainer>
      <FeaturedSections sections={data?.sections} />
      <FeaturedArticles articles={data?.articles} />
      <Footer>
        <FooterTitle display={['none', null, 'block']}>
          Clutter Basics
        </FooterTitle>
        <FooterCategories />
        <FooterSupport />
      </Footer>
    </div>
  );
};
