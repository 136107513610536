/* @jsx jsx */
import * as React from 'react';
import { useState } from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import {
  Button,
  COLORS,
  FontWeight,
  InfoModal,
  ResponsiveContainer,
  Text,
  UnstyledButton,
} from '@clutter/clean';

import {
  accountURL,
  billingURL,
  companyURL,
  movingURL,
  storageURL,
} from '../routes';

const Title = styled(Text.Title)`
  margin: 0 0 16px;
`;

const Paragraph = styled(Text.Headline)`
  margin: 16px 0 0;
`;

const Container = styled.section`
  color: ${COLORS.storm};
  border-top: 1px solid ${COLORS.grayBorder};
  border-bottom: 1px solid ${COLORS.grayBorder};
  text-align: center;
  margin: 48px 0;
  padding: 48px 0;
`;

const Link = styled.a`
  color: ${COLORS.tealPrimary};
  font-family: inherit;
  font-size: inherit;
  font-weight: ${FontWeight.Medium};
  text-decoration: underline;
`;

const Action = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  font-family: inherit;
  font-size: inherit;
  font-weight: ${FontWeight.Medium};
`;

const Section = styled.section`
  margin: 24px 0;
`;

const Dialog: React.FC<{ isOpen: boolean; onClose(): void }> = ({
  isOpen,
  onClose,
}) => {
  const [contact, setContact] = useState(false);
  return (
    <InfoModal isOpen={isOpen} handleModalClose={onClose}>
      <Text.Title size="medium">Ways to get help</Text.Title>
      <Section>
        <Text.Headline weight={FontWeight.Medium}>
          Browse popular help topics
        </Text.Headline>
        <Text.Body>
          Here in the Help Center, you’ll find immediate answers to questions on
          popular topics like <Link href={movingURL()}>Moving</Link>,{' '}
          <Link href={storageURL()}>Storage</Link>, and{' '}
          <Link href={billingURL()}>Billing</Link>.
        </Text.Body>
      </Section>
      <Section>
        <Text.Headline weight={FontWeight.Medium}>
          New to Clutter?
        </Text.Headline>
        <Text.Body>
          Whether you need help moving or are looking for self storage options,
          learn everything Clutter has to offer in our{' '}
          <Link href={companyURL()}>About Clutter</Link> resource area.
        </Text.Body>
      </Section>
      <Section>
        <Text.Headline weight={FontWeight.Medium}>
          Need help with items already in Storage?
        </Text.Headline>
        <Text.Body>
          <Link href={accountURL()}>Log in</Link> to access storage-related
          features and support.
        </Text.Body>
      </Section>
      <Section>
        <Text.Headline weight={FontWeight.Medium}>
          Questions about an upcoming appointment?{' '}
        </Text.Headline>
        <Text.Body>
          Modify your scheduled appointments in your{' '}
          <Link href={accountURL()}>Account Section</Link>.
        </Text.Body>
      </Section>
      {contact && (
        <Section>
          <Text.Headline weight={FontWeight.Medium}>Contact Us</Text.Headline>
          <Text.Body>
            You can also call us directly at (800) 805-4023.
          </Text.Body>
        </Section>
      )}
      {!contact && (
        <Button kind="secondary" fullWidth onClick={() => setContact(!contact)}>
          I Still Need Help
        </Button>
      )}
    </InfoModal>
  );
};

export const FooterSupport: React.FC = () => {
  const [displaying, setDisplaying] = useState(false);

  return (
    <Container>
      <ResponsiveContainer>
        <Title size="small">Still Need Help?</Title>
        <Paragraph>
          There are many issues that can be resolved online.{' '}
          <Action onClick={() => setDisplaying(true)}>
            Find all the ways to get help.
          </Action>
        </Paragraph>
      </ResponsiveContainer>
      <Dialog isOpen={displaying} onClose={() => setDisplaying(false)} />
    </Container>
  );
};
