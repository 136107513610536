/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { mq, SnackbarProvider } from '@clutter/clean';
import {
  DESKTOP_HEADER_HEIGHT,
  Header,
  MOBILE_HEADER_HEIGHT,
} from '@shared/header/header';

import WaveDesktopSVG from '@images/svg/help/wave-desktop.svg';
import WaveMobileSVG from '@images/svg/help/wave-mobile.svg';

const Wave = styled.div`
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: top right;

  ${mq({
    backgroundImage: [`url(${WaveMobileSVG})`, null, `url(${WaveDesktopSVG})`],
    backgroundSize: ['100% 167px', null, '100% 451px'],
  })}
`;

const Container = styled.div`
  ${mq({
    marginTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
    paddingTop: ['96px', null, '128px'],
  })}
`;

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <SnackbarProvider>
      <Wave>
        <Header />
        <Container>{children}</Container>
      </Wave>
    </SnackbarProvider>
  );
};
