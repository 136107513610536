import React from 'react';

import { Box, COLORS } from '@clutter/clean';

export type BlockProps = {
  background?: string;
  padding?: string | Array<string | null>;
  textAlign?: React.CSSProperties['textAlign'];
};

export const Block = React.forwardRef<
  HTMLDivElement,
  BlockProps & { children: React.ReactNode }
>(({ background = COLORS.cloud, padding, children, textAlign }, ref) => {
  return (
    <Box
      ref={ref}
      background={background}
      padding={padding ?? ['32px 24px', null, '100px 60px']}
      textAlign={textAlign}
    >
      <Box maxWidth="1480px" margin="0 auto">
        {children}
      </Box>
    </Box>
  );
});
