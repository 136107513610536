import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import { Brand } from '@root/components/www/home/brand';
import { Header } from '@shared/header/header';
import { useAbeetResultHero } from '@utils/hooks/use_abeet_result_hero';

import { Footer } from '../shared/content/footer';
import { SkipLink } from '../shared/skip_link';
import { ZebraContainer, ZebraPattern } from '../shared/zebra_container';

import { BusinessStats } from './home/business_stats';
import { CouponBanner } from './home/coupon_banner';
import { FAQ } from './home/faq';
import { Hero } from './home/hero';
import { HeroPlayer } from './home/hero_player';
import { LocationFooter } from './home/location_footer';
import { MovingInfo } from './home/moving_info';
import { Solutions } from './home/solutions';
import { TESTIMONIALS } from './home/testimonial_data';
import { WarehouseStorage } from './home/warehouse_storage';
import { TestimonialCarousel } from './shared/testimonial_carousel';

const MAIN_CONTENT_ID = 'main-content';

const Home: React.FC = () => {
  const hero = useAbeetResultHero();

  return (
    <>
      <SkipLink mainContentId={MAIN_CONTENT_ID} />
      <CouponBanner />
      <Header />
      <ZebraContainer
        zebra={ZebraPattern.DARK_THEN_LIGHT}
        id={MAIN_CONTENT_ID}
        role="main"
      >
        <Box margin={['0 0 36px', '0 0 48px']}>
          {hero === 'player' ? <HeroPlayer /> : <Hero />}
        </Box>
        <Box
          background={`${COLORS.grayBackground} !important`}
          margin={['48px auto', null, '100px auto']}
          padding="0 24px"
        >
          <Solutions />
        </Box>
        <Box padding={['60px 24px', '100px 24px']} textAlign="center">
          <Text.Title size="large">
            Here’s what Clutter customers are saying
          </Text.Title>
          <TestimonialCarousel testimonials={TESTIMONIALS} />
        </Box>
        <BusinessStats />
        <MovingInfo />
        <WarehouseStorage />
        <Box
          style={{ background: COLORS.tealBackground }}
          padding={['60px 24px', '72px 24px']}
        >
          <Box maxWidth="1260px" margin="0 auto">
            <Brand />
          </Box>
        </Box>
        <FAQ />
      </ZebraContainer>
      <LocationFooter />
      <Footer />
    </>
  );
};

export { Home };
