import React from 'react';

import { Box } from '@clutter/clean';

export const TabContainer: React.FC<
  React.ComponentPropsWithoutRef<typeof Box.Flex>
> = ({ children, ...props }) => {
  return (
    <Box.Flex
      overflow={['auto', null, 'initial']}
      gap={['32px', null, '56px']}
      {...props}
    >
      {children}
    </Box.Flex>
  );
};
