import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

const ProgressWrapper = styled.div`
  display: table;
  border-spacing: 5px 0px;
  width: 100%;
  height: 8px;
`;

const ProgressStep = styled.div<{ complete: boolean }>`
  background: ${({ complete }) =>
    complete ? COLORS.tealPrimary : COLORS.grayBorder};
  border-radius: 3px;
  display: table-cell;
`;

interface IProgressStepperProps {
  currentStep: number;
  totalSteps: number;
}

export const ProgressStepper: React.FC<IProgressStepperProps> = ({
  currentStep,
  totalSteps,
}) => (
  <ProgressWrapper>
    {Array.from({ length: totalSteps }, (_, i) => {
      return <ProgressStep key={i} complete={i < currentStep} />;
    })}
  </ProgressWrapper>
);
