import React from 'react';

import { Box, COLORS, Text, UnstyledButton } from '@clutter/clean';

export const TabItem: React.FC<{
  name: string;
  active: boolean;
  onClick: () => void;
}> = ({ name, active, onClick }) => {
  return (
    <Box
      padding="0 0 8px"
      margin={['0 0 16px', null, 'initial']}
      style={{
        borderBottom: active
          ? `4px solid ${COLORS.tealPrimary}`
          : `4px solid transparent`,
      }}
    >
      <UnstyledButton onClick={onClick}>
        <Text.Headline
          color={active ? COLORS.tealPrimary : COLORS.tealDark}
          style={{ whiteSpace: 'nowrap' }}
        >
          {name}
        </Text.Headline>
      </UnstyledButton>
    </Box>
  );
};
