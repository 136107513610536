import React from 'react';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { ExternalContent, mq } from '@clutter/clean';
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@shared/header/header';

const Content = styled(ExternalContent)`
  p {
    ${mq({
      scrollMarginTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
    })}
  }
`;

export const CaliforniaPrivacyRights = () => (
  <Content>
    <p>
      <strong>California Privacy Rights</strong>
    </p>
    <p>
      <strong>Last Updated</strong>: December 19, 2022
    </p>
    <p>
      This California Privacy Notice (the &ldquo;<strong>CA Disclosures</strong>
      &rdquo;) supplements the information contained in our Privacy Policy and
      applies solely to individual residents of the State of California (&ldquo;
      <strong>consumers</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;).
    </p>
    <p>
      These CA Disclosures provide additional information about how we collect,
      use, disclose and otherwise process personal data of individual residents
      of the State of California within the scope of the California Consumer
      Privacy Act of 2018, as amended by the California Consumer Privacy Rights
      Act (collectively, the &ldquo;<strong>CCPA</strong>&rdquo;). Unless
      otherwise expressly stated, all terms in these CA Disclosures have the
      same meaning as defined in our{' '}
      <Link to="/legal/privacy_policy">Privacy Policy</Link> or as otherwise
      defined the CCPA.
    </p>
    <p>
      These CA Disclosures apply to you if you are a resident of the state of
      California. California law requires us to disclose certain information
      regarding the categories of personal information we collect and the rights
      you may have in connection with such information. For purposes of this
      section, &ldquo;personal information&rdquo; has the meaning provided by
      the California Consumer Privacy Act (the &ldquo;<strong>CCPA</strong>
      &rdquo;), and does not include information that is (i)&nbsp;publicly
      available, (ii)&nbsp;deidentified or aggregated such that it is not
      capable of being associated with you, or (iii)&nbsp;that is excluded from
      the CCPA&rsquo;s scope, such as personal information covered by certain
      sector-specific privacy laws, such as the HIPAA, the FCRA, GLBA or the
      Driver&rsquo;s Privacy Protection Act of 1994. These CA Disclosures also
      do not apply to information relating to our employees, contractors, and
      other personnel.
    </p>
    <p id="information-collection">
      <strong>HOW WE COLLECT AND USE INFORMATION</strong>
    </p>
    <p>
      We may collect personal information from and about you for a variety of
      purposes. For example, we use personal information to fulfill your
      requests, process your transactions and provide our Services to you; to
      communicate with you; for marketing and advertising purposes; to monitor,
      improve, and develop our products and Services; and to protect the
      security and integrity of our business, comply with legal requirements and
      obligations; for our business and operational purposes; and as otherwise
      permitted or required by law.
    </p>
    <p>
      To learn more about the types of personal information we collect and the
      purposes for which we collect this information, please refer to the{' '}
      <Link to="/legal/privacy_policy#information-collection">
        <strong>How We Collect Your Information</strong>
      </Link>{' '}
      of the <Link to="/legal/privacy_policy">Privacy Policy</Link>.
    </p>
    <p>
      In the last 12 months, we may have collected the following categories of
      personal information:
    </p>
    <ul>
      <li>
        <strong>Identifiers</strong>, such as your first and last name, physical
        address, phone number, email address or other similar identifiers.
      </li>
      <li>
        <strong>California Customer Records</strong>{' '}
        (Cal.&nbsp;Civ.&nbsp;Code&nbsp;&sect;&nbsp;1798.80(e)), such as contact
        information and payment information.
      </li>
      <li>
        <strong>Commercial Information</strong>, such as billing and purchase
        history.
      </li>
      <li>
        <strong>Internet/Network Information</strong>, such as device
        information, logs and analytics data.
      </li>
      <li>
        <strong>Geolocation Data</strong>, such as location information from
        your device or generated based on IP address.
      </li>
      <li>
        <strong>Professional or Employment Information, </strong>such as the
        name and address of the company you work for in connection with job
        applications.&nbsp;
      </li>
      <li>
        <strong>Non-Public Education Information</strong> (20 U.S.C. &sect;
        1232g, 34 C.F.R. Part 99) from job applicants only, such as educational
        history and degree.
      </li>
      <li>
        <strong>Sensory Information</strong>, such as your profile photograph on
        social media pages and recordings of phone calls with us, where
        permitted by law.
      </li>
      <li>
        <strong>Other Personal Data</strong>, including list of personal
        belongings, address book information, and information you post on our
        social media pages or otherwise provide to us.
      </li>
      <li>
        <strong>Inferences</strong>, such as predictions about your interests
        and preferences.
      </li>
    </ul>
    <p>
      For more information about the personal data that we collect and how we
      collect it, please refer to the{' '}
      <Link to="/legal/privacy_policy#information-collection">
        <strong>How We Collect Your Information</strong>
      </Link>{' '}
      section of our <Link to="/legal/privacy_policy">Privacy Policy</Link>
    </p>
    <p id="information-disclosure">
      <strong>DISCLOSURE OF PERSONAL INFORMATION</strong>
    </p>
    <p>
      We share personal information with third parties for business purposes or
      we may sell your personal information to third parties, subject to your
      Right to Opt-Out of those sales (see{' '}
      <strong>Your Rights and Choices</strong>
      below). The categories of third parties to whom we sell or disclose your
      personal information for a business purpose include: (i)&nbsp;other brands
      and affiliates in our family of companies; (ii)&nbsp;our service providers
      and advisors; (iii)&nbsp;marketing and strategic partners;
      (iv)&nbsp;promotional partners; (v)&nbsp;ad networks and advertising
      partners; (vi)&nbsp;analytics providers; (vii)&nbsp;social networks.&nbsp;
    </p>
    <p>
      In the previous 12&nbsp;months, we may have disclosed the following
      categories of personal information to third parties for a business
      purpose:
    </p>
    <ul>
      <li>
        All of the categories of personal information we collect, explained in
        the{' '}
        <Link to="#information-collection">
          <strong>How We Collect and Use Information</strong>
        </Link>{' '}
        section above.
      </li>
    </ul>
    <p>
      Unless you have exercised your Right to Opt-Out of personal information
      sales, we may sell or share personal information to third parties for
      monetary or other valuable consideration. The third parties to whom we
      sell or share personal information may use such information for their own
      purposes in accordance with their own privacy statements, which may
      include reselling this information to additional third parties.
    </p>
    <p>
      In the previous 12 months, we may have sold or shared the following
      categories of personal information to third parties:
    </p>
    <ul>
      <li>Identifiers</li>
      <li>Commercial Information</li>
      <li>Internet / Network Information</li>
    </ul>
    <p>
      As is common practice among companies that operate online, we have
      permitted third party advertising networks, social media companies and
      other third party businesses collect and disclose your personal
      information (including Internet / Network Information, Commercial
      Information, and Inferences) directly from your browser or device through
      cookies or tracking technologies when you visit or interact with our
      websites, use our apps or otherwise engage with us. To learn more, please
      see the{' '}
      <Link to="/legal/privacy_policy#online-advertising">
        <strong>Online Advertising and Third-Party Tracking</strong>
      </Link>{' '}
      section of our <Link to="/legal/privacy_policy">Privacy Policy</Link>.
    </p>
    <p>
      To learn more about how we disclose data to third parties, please refer to
      the{' '}
      <Link to="/legal/privacy_policy#information-sharing">
        <strong>How We Share Your Information</strong>
      </Link>{' '}
      section of our <Link to="/legal/privacy_policy">Privacy Policy</Link>.
    </p>
    <p id="rights-and-choices">
      <strong>YOUR RIGHTS AND CHOICES</strong>
    </p>
    <p>
      As a California resident, you may be able to request to exercise the
      following rights:
    </p>
    <ul>
      <li>
        <strong>The Right to Know</strong> any or all of the following
        information relating to your personal information we have collected and
        disclosed in the last 12&nbsp;months, upon verification of your
        identity:
      </li>
      <ul>
        <li>
          The specific pieces of personal information we have collected about
          you;
        </li>
        <li>
          The categories of personal information we have collected about you;
        </li>
        <li>The categories of sources of the personal information;</li>
        <li>
          The categories of personal information that we have disclosed to third
          parties for a business purpose, and the categories of recipients to
          whom this information was disclosed;
        </li>
        <li>
          The categories of personal information we have sold and the categories
          of third parties to whom the information was sold; and
        </li>
        <li>
          The business or commercial purposes for collecting or selling the
          personal information.
        </li>
      </ul>
      <li>
        <strong>The Right to Request Correction</strong> of any inaccuracies in
        your personal data, taking into account the nature of the personal data
        and the purposes of the processing of your personal data.
      </li>
      <li>
        <strong>The Right to Request Deletion</strong> of personal information
        we have collected from you, subject to certain exceptions.
      </li>
      <li>
        <strong>
          The Right to Opt-Out of Personal Information Sales or Sharing
        </strong>
        , specifically you have the right to direct us not to &ldquo;sell&rdquo;
        your personal information to third parties for monetary or other
        valuable consideration, or &ldquo;share&rdquo; your personal information
        to third parties for cross-context behavioral advertising purposes. If
        you are under the age of 16, you have the right to opt in, or to have a
        parent or guardian opt in on your behalf, to such sales or sharing.
      </li>
    </ul>
    <p>
      You also have the right to be free of discrimination for exercising these
      rights. However, please note that if the exercise of these rights limits
      our ability to process personal information (such as in the case of a
      deletion request), we may no longer be able to provide you our products
      and services or engage with you in the same manner.
    </p>
    <p id="exercise-rights">
      <strong>HOW TO EXERCISE YOUR CALIFORNIA PRIVACY RIGHTS</strong>
    </p>
    <p>
      You may submit a request to exercise your California Consumer Rights
      through one of the mechanisms described below. We will need to verify your
      identity before processing your request, which may require us to request
      additional personal information from you or require you to log into your
      account, if you have one. In certain circumstances, we may decline or
      limit your request, particularly where we are unable to verify your
      identity or locate your information in our systems, or as permitted by
      law.
    </p>
    <p>
      To exercise your Right to Know, Right to Correct, and/or Right to
      Deletion, please submit a request by:
    </p>
    <ul>
      <li>
        Emailing{' '}
        <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
          privacy@clutter.com
        </a>{' '}
        with the subject line &ldquo;California Rights Request,&rdquo; or
      </li>
      <li>
        Filling out our{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSde0aIw-VArtAaFEgzdUhrDgvNxPyuydxZgsxoETNcJJQfdIA/viewform"
          target="_blank"
          rel="noopener"
        >
          California Resident Rights Request Form
        </a>
        .&nbsp;
      </li>
    </ul>
    <p>
      To exercise the Right to Opt-Out of Personal Information Sales, or Opt-Out
      of the Personal Information Sharing with third parties for cross-context
      behavioral advertising purposes, you may submit a request by clicking the
      following link:{' '}
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSd2WC6R93jB7GOQSLkyu3GGFVP0-qlQGUQGuJgKNPoGiLwrrA/viewform"
        target="_blank"
        rel="noopener"
      >
        Do Not Sell or Share My Personal Information
      </a>
    </p>
    <p>
      Please see the sections of our{' '}
      <Link to="/legal/privacy_policy">Privacy Policy</Link> called{' '}
      <Link to="/legal/privacy_policy#cookies">
        <strong>Cookies and Other Tracking Technologies</strong>
      </Link>{' '}
      and{' '}
      <Link to="/legal/privacy_policy#online-advertising">
        <strong>Online Advertising and Third-Party Tracking</strong>
      </Link>{' '}
      for more information about how third parties use cookies and related
      technologies to collect information automatically on our Services, and the
      choices you may have in relation to those activities.
    </p>

    <p id="submit-request">
      <strong>To Submit a Request as an Authorized Agent</strong>
    </p>
    <p>
      In certain circumstances, you are permitted to use an authorized agent (as
      that term is defined by the CCPA) to submit requests on your behalf
      through the designated methods set forth above where we can verify the
      authorized agent&rsquo;s authority to act on your behalf by:&nbsp;
    </p>
    <ul>
      <li>For requests to know or delete personal data:&nbsp;</li>
      <ul>
        <li>
          Receiving a power of attorney valid under the laws of California from
          you or your authorized agent; or
        </li>
        <li>Receiving sufficient evidence to show that you have:</li>
        <ul>
          <li>
            Provided the authorized agent signed permission to act on your
            behalf;&nbsp;
          </li>
          <li>
            Verified your own identity directly with us pursuant to the
            instructions set forth in these CA Disclosures; and&nbsp;
          </li>
          <li>
            Directly confirmed with us that you provided the authorized agent
            permission to submit the request on your behalf.&nbsp;
          </li>
        </ul>
      </ul>
      <li>
        For requests to opt-out of personal data sales: Receiving a signed
        permission demonstrating your authorized agent has been authorized by
        you to act on your behalf.&nbsp;
      </li>
    </ul>
    <p>
      We may deny a request in the event we are not able to verify the
      authorized agent&rsquo;s authority to act on your behalf.
    </p>
    <p id="minors">
      <strong>MINORS</strong>
    </p>
    <p>
      We do not sell or share the personal information of consumers we know to
      be less than 16&nbsp;years of age, unless we receive affirmative
      authorization (the &ldquo;<strong>Right to Opt-In</strong>&rdquo;) from
      either the minor who is between 13 and 16&nbsp;years of age, or the parent
      or guardian of a minor less than 13&nbsp;years of age. Please contact us
      at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>{' '}
      to inform us if you, or your minor child, are under the age of 16. If you
      are under the age of 18 and you want to remove your name or comments from
      our website or publicly displayed content, please contact us directly at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>
      . We may not be able to modify or delete your information in all
      circumstances.
    </p>
    <p id="shine-the-light">
      <strong>CALIFORNIA&rsquo;S &ldquo;SHINE THE LIGHT&rdquo; LAW</strong>
    </p>
    <p>
      California residents that have an established business relationship with
      us have rights to know how their information is disclosed to third parties
      for their direct marketing purposes under California&rsquo;s &ldquo;Shine
      the Light&rdquo; law, or the right to opt out of such practices (
      <em>See </em>Cal. Civ. Code &sect;1798.83). To opt out of this type of
      sharing, please submit a request by filling out our{' '}
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSde0aIw-VArtAaFEgzdUhrDgvNxPyuydxZgsxoETNcJJQfdIA/viewform"
        target="_blank"
        rel="noopener"
      >
        <strong>California Resident Rights Request Form</strong>
      </a>
      .&nbsp;
    </p>
    <p id="financial-incentives">
      <strong>NOTICE OF FINANCIAL INCENTIVES</strong>
    </p>
    <p>
      In addition, we may offer you financial incentives for the collection,
      sale, retention, and use of your personal information as permitted by the
      CCPA that can, without limitation, result in reasonably different prices,
      rates, or quality levels.&nbsp;The material aspects of any financial
      incentive will be explained and described in its program terms. Please
      note that participating in incentive programs is entirely optional, you
      will have to affirmatively opt-in to the program and you can opt-out of
      each program (i.e., terminate participation and forgo the ongoing
      incentives) prospectively by following the instructions in the applicable
      program description and terms.&nbsp;We may add or change incentive
      programs, and/or their terms by posting notice on the program descriptions
      and terms linked to above, so check them regularly.
    </p>
    <p>
      Each financial incentive or price or service difference related to the
      collection and use of personal information is based upon our reasonable,
      good-faith determination of the estimated value of such information to our
      business, taking into consideration the value of the offer itself and the
      anticipated revenue generation that may be realized by rewarding brand
      loyalty. We calculate the value of the offer and financial incentive by
      using the expense related to the offer.
    </p>
  </Content>
);
