import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, mq, toggleStyleValue } from '@clutter/clean';
import { linkStyle } from '@root/components/www/shared/shared_styles';
import { DeferredImage } from '@shared/deferred_image';
import { useSlide } from '@utils/hooks';

import arrowDown from '@images/icons/arrow_down.svg';
import arrowUp from '@images/icons/arrow_up.svg';

interface ICollapsibleCardProps {
  children: React.ReactNode;
  title: string;
  position: number;
  className?: string;
  maxHeightOverride?: number;
  toggleOpenSrc?: string;
  toggleCloseSrc?: string;
  onToggle?(isOpen: boolean): void;
}

const CardContainer = styled.div`
  position: relative;
  padding: 24px;
  font-family: Basier Square;
  color: ${COLORS.panther};

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    margin-bottom: 48px;
  }
`;

const Title = styled.h3`
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  height: 34px;
  display: flex;
  align-items: center;
`;

const Toggler = styled.div<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 10px;

  & img:first-of-type,
  & img:last-of-type {
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }

  & img:first-of-type {
    transform: ${toggleStyleValue('isOpen', 'rotate(0deg)', 'rotate(180deg)')};
    opacity: ${toggleStyleValue('isOpen', 1, 0)};
  }

  & img:last-of-type {
    transform: ${toggleStyleValue('isOpen', 'rotate(-180deg)', 'rotate(0)')};
    opacity: ${toggleStyleValue('isOpen', 0, 1)};
  }
`;

const Card = styled.div`
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
`;

const CardContent = styled.div`
  cursor: default;
  padding-top: 15px;
  font-style: normal;
  font-stretch: normal;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: left;

  & p {
    margin-bottom: 15px;
  }

  & a {
    ${linkStyle};
  }
`;

const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  ${mq({
    fontSize: ['20px', null, '32px'],
  })}
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CollapsibleCard: React.FC<ICollapsibleCardProps> = (props) => {
  const {
    title,
    position,
    className,
    children,
    toggleCloseSrc,
    toggleOpenSrc,
    onToggle,
    maxHeightOverride,
  } = props;
  const [ref, maxHeight, isOpen, toggleIsOpen] = useSlide<HTMLDivElement>(
    false,
    maxHeightOverride,
  );

  const id = `question-${position}`;

  return (
    <CardContainer className={className}>
      <ContentRow>
        <ContentCol>
          <Title
            onClick={() => {
              toggleIsOpen();
              if (onToggle) {
                onToggle(!isOpen);
              }
            }}
            aria-controls={id}
            aria-expanded={isOpen}
          >
            {title}
          </Title>
          <Card style={{ maxHeight }}>
            <CardContent id={id} ref={ref}>
              {children}
            </CardContent>
          </Card>
        </ContentCol>
        <Toggler
          onClick={() => {
            toggleIsOpen();
            if (onToggle) {
              onToggle(!isOpen);
            }
          }}
          aria-label={isOpen ? `Hide '${title}'` : `Show '${title}'`}
          aria-controls={id}
          aria-expanded={isOpen}
          isOpen={isOpen}
          role="button"
          tabIndex={0}
        >
          <DeferredImage src={toggleCloseSrc ?? arrowUp} />
          <DeferredImage src={toggleOpenSrc ?? arrowDown} />
        </Toggler>
      </ContentRow>
    </CardContainer>
  );
};

export { CollapsibleCard };
