import React from 'react';

type Content = {
  question: string;
  answer: React.ReactNode;
};

export const DOOR_TO_DOOR_FAQ_CONTENT: Content[] = [
  {
    question: 'How much space do I need?',
    answer: (
      <>
        Clutter is flexible—you can always add and remove items, and you’ll only
        pay for what you store. To give you an accurate rate, we’ll help you
        estimate how much space you need while you’re getting your quote. If on
        the day of your appointment, you use less or more space than originally
        quoted for, we’ll let you know and adjust your monthly rate accordingly.
      </>
    ),
  },
  {
    question: 'Can I add more items to storage?',
    answer: (
      <>
        Of course! Any time you want to add items to storage, simply log into
        your account portal and schedule a pickup. All pickups and deliveries
        are charged per hour.
      </>
    ),
  },
  {
    question: 'How do deliveries work?',
    answer: (
      <>
        You can use your online photo catalog to select what items you want
        returned and when, from the convenience of your home. Our Full Service
        deliveries are charged per hour. On select eligible items, you can have
        FedEx ground ship your items in storage anywhere nationwide for a flat
        rate fee.
      </>
    ),
  },
  {
    question: 'Where are your warehouses located?',
    answer: (
      <>
        Our warehouses are located outside the city so we can pass along savings
        to you. We have locations across North America in almost every major
        metropolitan area of the United States and Toronto.
      </>
    ),
  },
  {
    question: 'What features do your warehouses have?',
    answer: (
      <>
        Our state of the art warehouses are clean and have 24/7 security. Only
        trained warehouse staff can access the facilities, so your items are in
        good hands.
      </>
    ),
  },
];

export const MOVING_FAQ_CONTENT: Content[] = [
  {
    question: 'What services do you offer?',
    answer: (
      <>
        Clutter services same-day moves in almost every metropolitan area of the
        United States.
      </>
    ),
  },
  {
    question: 'What does Clutter do to guarantee the best moving experience?',
    answer: (
      <>
        After you get a quote, your confirmation email will have a link to
        upload photos for a digital walkthrough. These photos help us prepare to
        deliver a high quality moving experience, at the lowest quote possible.
      </>
    ),
  },
  {
    question: 'Can your team help me pack?',
    answer: (
      <>
        Yes, we can do as little or as much packing as you need. We always
        protect large and fragile items with moving blankets and edge
        protectors. You can also add packing supplies to your move such as
        boxes, tape, packing paper, and bubble wrap or choose to provide your
        own.
      </>
    ),
  },
  {
    question: 'When should I book my move?',
    answer: (
      <>
        We can schedule moves with as little as three day’s notice, subject to
        availability. Availability books up fast, so we recommend booking as
        early as possible to lock in your date.
      </>
    ),
  },
  {
    question: 'What are your service areas?',
    answer: (
      <>
        Clutter can either move you or arrange a move with a trusted partner
        anywhere in the continental United States.
      </>
    ),
  },
  {
    question: 'How many movers and trucks do you send?',
    answer: (
      <>
        During the process of calculating your quote, we’ll ask some questions
        about the size of your move to help us determine the amount of movers
        and trucks you’ll need. Your quote will include the number of movers and
        trucks we’ll send on the day of your move.
        <br />
        <br />
        If for any reason, you feel the number of quoted movers is too high or
        too low, make a booking and call us to revise your quote.
      </>
    ),
  },
];
