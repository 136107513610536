import React from 'react';

import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';
import { Testimonial } from '@root/components/www/shared/testimonial_carousel';
import { ResponsiveDisplay } from '@shared/formatters/responsive_display';

import amber from '@images/home/testimonials/amber.jpg';
import dale from '@images/home/testimonials/dale.jpg';
import jo from '@images/home/testimonials/jo.jpg';
import judy from '@images/home/testimonials/judy.jpg';
import kiana from '@images/home/testimonials/kiana.jpg';
import shannon from '@images/home/testimonials/shannon.jpg';

const Highlight = styled.span`
  color: ${COLORS.tealPrimary};
`;

export const TESTIMONIALS: Testimonial[] = [
  {
    image: {
      url: dale,
      alt: 'Happy Customer Sharing Moving Experience with Clutter',
    },
    tagline: (
      <>
        Dale’s company needed to quickly{' '}
        <Highlight>move PPE across the country.</Highlight>
      </>
    ),
    quote: (
      <>
        We had critical supplies delayed on the West Coast and needed a quick
        solution. The Clutter team helped us to move products through their
        facility same day and get them distributed throughout the US where
        needed. Clutter helped us to get vital PPE shipments into the hands of
        health care providers where needed.
      </>
    ),
    location: 'Clutter for Enterprise customer',
  },
  {
    image: {
      url: judy,
      alt: 'Clutter packers and movers customer experience feedback',
    },
    tagline: (
      <>
        Judy’s company was <Highlight>moving into a new office.</Highlight>
      </>
    ),
    quote: (
      <>
        <ResponsiveDisplay mode="only" sm>
          Our company relocated into a cool office, but we had some furniture we
          didn’t want to sell or squeeze into the new office. Clutter picked up
          our items easy peasy and stored them. I would use them again in a
          heartbeat, including for my personal items. Clutter is an exceptional
          alternative to outrageously expensive storage.
        </ResponsiveDisplay>
        <ResponsiveDisplay mode="not" sm>
          Our company relocated into a cool office, but we had some furniture we
          didn’t want to sell or squeeze into the new office. Clutter picked up
          our items easy peasy and stored them. The twist is that it’s not
          traditional storage, they give you an itemization of what you’ve
          stored, along with pretty pictures of everything. When you’re ready to
          retrieve items, it’s simple to schedule a delivery within a few days.
          <br />
          <br />I would use them again in a heartbeat, including for my personal
          items. Clutter is an exceptional alternative to outrageously expensive
          storage.
        </ResponsiveDisplay>
      </>
    ),
    location: 'Smart Storage in the Bay Area',
  },
  {
    image: {
      url: amber,
      alt: 'Happy couples after a great moving with Clutter',
    },
    tagline: (
      <>
        Amber needed space while <Highlight>renovating her home.</Highlight>
      </>
    ),
    quote: (
      <>
        <ResponsiveDisplay mode="only" sm>
          Clutter’s service is genius! Now that we can finally breathe, we
          wanted a way to get stuff out of the way while we fix up our home,
          without needing to make decisions about what to keep, donate, or sell.
          With everything inventoried and the option to have Clutter bring back
          any item at any time, we could store our extra belongings without
          stress or arguments.
        </ResponsiveDisplay>
        <ResponsiveDisplay mode="not" sm>
          Clutter’s service is genius! My family has had every scenario that
          leads to having too much stuff—back to back moves, graduate school,
          hospitalization, a new baby, and parents passing away. All of this
          resulted in a big mess.
          <br />
          <br />
          Now that we can finally breathe, we wanted a way to get stuff out of
          the way while we fix up our home, without needing to make decisions
          about what to keep, donate, or sell. With everything inventoried and
          the option to have Clutter bring back any item at any time, we could
          store our extra belongings without stress or arguments.
        </ResponsiveDisplay>
      </>
    ),
    location: 'Smart Storage in the Bay Area',
  },
  {
    image: {
      alt: 'Clutter customer review after a successful moving experience',
      url: kiana,
    },
    tagline: (
      <>
        Kiana was <Highlight>moving</Highlight>
        <br />
        into her first home.
      </>
    ),
    quote: (
      <>
        The day of the move, the Clutter movers arrived on time and worked hard
        to complete the job as quickly as possible. The price point was terrific
        and there were no hidden fees. The price Clutter quoted me was exactly
        what was on the final bill. I will definitely recommend Clutter to
        friends and family!
      </>
    ),
    location: 'Moving in Los Angeles',
  },
  {
    image: {
      url: shannon,
      alt: 'Happy couples after a successful moving experience with Clutter',
    },
    tagline: (
      <>
        Shannon was looking to easily{' '}
        <Highlight>store seasonal items.</Highlight>
      </>
    ),
    quote: (
      <>
        I truly swear by this service. Clutter is extremely convenient storage
        without the hassle of having to pack, move, and store things yourself.
        Anytime I need items returned, it’s a simple website request away. I do
        this often with my seasonal items that take up unnecessary space, like
        Christmas decorations.
        <ResponsiveDisplay mode="not" sm>
          <br />
          <br />
          All of the staff have been professional and efficient. The two most
          recent clutter associates that came to my home were diligent and took
          extreme care when packing my items. I really appreciate that!
        </ResponsiveDisplay>
      </>
    ),
    location: 'Smart Storage in Chicago',
  },
  {
    image: {
      url: jo,
      alt: 'Clutter’s seamless moving services praised by customer',
    },
    tagline: (
      <>
        Jo needed to <Highlight>manage a move while traveling.</Highlight>
      </>
    ),
    quote: (
      <>
        I was not in the country when I booked Clutter and managed the moving
        process remotely.{' '}
        <ResponsiveDisplay mode="not" sm>
          Communication with the representative was efficient and
          straightforward.{' '}
        </ResponsiveDisplay>
        Two movers arrived on time on the moving day. They packed up and loaded
        a room full of things on the truck in just one hour, which was pretty
        impressive! It was also very convenient that I could track the moving
        progress through a link they provided me.
      </>
    ),
    location: 'Smart Storage in Los Angeles',
  },
];
