export const mainURL = () => '/help';
export const searchURL = () => '/help/search';
export const articleURL = (articleID = ':articleID') =>
  `/help/articles/${articleID}`;
export const categoryURL = (categoryID = ':categoryID') =>
  `/help/categories/${categoryID}`;
export const sectionURL = (
  categoryID = ':categoryID',
  sectionID = ':sectionID',
) => `/help/categories/${categoryID}/sections/${sectionID}`;

export const companyURL = () => '/company';
export const accountURL = () => 'https://account.clutter.com/';

export const movingURL = () => '/help/categories/1500000599982';
export const storageURL = () => '/help/categories/1500000599942';
export const billingURL = () =>
  '/help/categories/1500000585701/sections/1500000936281';

export const assetURL = (path: string) =>
  `https://clutter-help.s3.us-east-1.amazonaws.com/${path}`;
