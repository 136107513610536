import * as React from 'react';

// Types
import { IServerData } from '@shared/server_data_context';

import { ClientRoutingConfig } from '../shared/client_routing_config';

// Components
import { App } from './app';

export const ClientWWW: React.FC<{
  serverData: IServerData;
  baseUrl: string;
}> = ({ baseUrl, serverData }) => (
  <ClientRoutingConfig>
    <App serverData={serverData} baseUrl={baseUrl} />
  </ClientRoutingConfig>
);
