import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';
import { AspectRatio } from '@shared/aspect_ratio';
import { Button } from '@shared/button';

import { PinnedFooterContainer } from '../../shared/pinned_footer_container';

import leftArrow from '@images/icons/arrow_left.svg';

const PaddedFooter = styled(PinnedFooterContainer)`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

const PrevButton = styled(Button)`
  padding: 15px 16px 14px 16px;
  color: ${COLORS.tealPrimary};
  border: 1px solid ${COLORS.tealPrimary};
  width: 48px;
  min-width: unset;
`;

const NextButton = styled(Button)`
  padding: 15px 32px;
  font-size: 16px;
  min-width: unset;
  box-shadow: none;
`;

export const GuideFooter: React.FC<{ onPrev(): void; onNext(): void }> = ({
  onPrev,
  onNext,
}) => (
  <PaddedFooter show>
    <PrevButton onClick={() => onPrev()}>
      <AspectRatio ratio={16 / 17}>
        <img src={leftArrow} alt="previous" />
      </AspectRatio>
    </PrevButton>
    <NextButton onClick={() => onNext()} themeName="action">
      Next
    </NextButton>
  </PaddedFooter>
);
