import { css } from '@emotion/react';

import { COLORS } from '@clutter/clean';

export const textCenter = css`
  text-align: center;
`;

export const linkStyle = css`
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-decoration: underline;
  color: ${COLORS.tealDark};
  cursor: pointer;
`;
