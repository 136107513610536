import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS, mq, toggleStyleValue } from '@clutter/clean';
import { FunnelEntryPoint } from '@shared/funnel_entry_point';
import { FunnelEntryPointForm } from '@shared/funnel_entry_point_form';
import { FunnelEntryPointErrorMessage } from '@shared/funnel_entry_point_message';
import { FunnelEntryPointSubmitButton } from '@shared/funnel_entry_point_submit_button';
import { FunnelEntryPointZipInput } from '@shared/funnel_entry_point_zip_input';
import { ServiceSelection } from '@shared/service_selector/constants';

interface IZipCTAProps {
  id?: string;
  className?: string;
  wtConfig: {
    container: string;
    [metadataKey: string]: string;
  };
  buttonTestId?: string;
  wrap?: boolean;
  errorLocation?: 'left' | 'above';
  inputPlaceholder?: string;
  service?: ServiceSelection;
  inputId?: string;
}

const Container = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  position: relative;
  ${({ flexWrap }) =>
    mq({
      flexWrap: flexWrap === false ? 'initial' : ['wrap', 'initial'],
    })}
  margin: 0 -8px -16px;

  input {
    height: 56px;
    flex-grow: 1;
    margin: 0 8px 16px;
    width: 100%;
    ${toggleStyleValue('flexWrap', 'initial', '100%')}

    ::-webkit-input-placeholder {
      font-weight: 500;
    }
  }

  button {
    margin: 0 8px 16px;
    padding-right: 0;
    padding-left: 0;
    text-transform: none;
    white-space: nowrap;
    flex-grow: 1;
    width: 100%;

    ${mq({
      maxWidth: [null, '248px'],
    })}
  }
`;

const leftLocationStyles = css`
  bottom: 0;
  left: -16px;
  transform: translate(-100%);
`;

const aboveLocationStyles = css`
  bottom: 120%;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
`;

const ErrorMessage = styled.div<{ location: 'above' | 'left' }>`
  position: absolute;
  z-index: 10;
  background: ${COLORS.flower};
  padding: 10px;
  font-size: 16px;
  margin: 0 auto;
  left: 50%;
  color: ${COLORS.toucan};
  border-radius: 2px;
  font-weight: bold;
  height: 56px;
  display: flex;
  align-items: center;

  ${({ location }) =>
    location === 'above' ? aboveLocationStyles : leftLocationStyles};

  &::after {
    bottom: -3px;
    content: ${({ location }) => (location === 'left' ? 'none' : "' '")};
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${COLORS.flower};
    left: 50%;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`;

const ZipCTA: React.FC<IZipCTAProps> = ({
  id = 'zip_cta',
  className,
  buttonTestId,
  wrap,
  errorLocation = 'above',
  service,
  wtConfig,
}) => {
  return (
    <FunnelEntryPoint initialService={service} wtConfig={wtConfig}>
      <FunnelEntryPointForm className={className} id={id}>
        <Container flexWrap={wrap}>
          <FunnelEntryPointZipInput />
          <FunnelEntryPointSubmitButton
            type="submit"
            kind="primary"
            size="large"
            data-test-id={buttonTestId}
          />
          <FunnelEntryPointErrorMessage>
            <ErrorMessage location={errorLocation} />
          </FunnelEntryPointErrorMessage>
        </Container>
      </FunnelEntryPointForm>
    </FunnelEntryPoint>
  );
};

export { ZipCTA };
