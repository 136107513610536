import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text } from '@clutter/clean';
import { AspectRatio } from '@shared/aspect_ratio';

type CaptionKind = 'Good' | 'Bad';

const BorderedAspectRatio = styled(AspectRatio)`
  & + & {
    border-top: 8px ${COLORS.panther} solid;
  }
`;

const ImageWrapper = styled.div`
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0;
`;

const CaptionWrapper = styled.div<{ captionKind: CaptionKind }>`
  position: absolute;
  line-height: 40px;
  top: 0;
  right: 0;
  padding: 12px 18px;
  background: ${({ captionKind }) =>
    captionKind === 'Good' ? COLORS.tealJungle : COLORS.toucan};
  color: ${COLORS.cloud};
`;

const MainImage = styled.img`
  display: block;
`;

const CaptionText = styled(Text.Title)`
  display: flex;
  gap: 12px;
`;

export interface IGuideImage {
  imagePath: string;
  captionKind?: CaptionKind;
  captionText: string;
}

interface IGuideImageProps {
  imageData: IGuideImage;
}

const ICON_FOR_CAPTION_KIND = {
  Good: '✓',
  Bad: '✗',
};

export const GuideImage: React.FC<IGuideImageProps> = ({ imageData }) => {
  const { imagePath, captionKind = 'Good', captionText } = imageData;
  return (
    <BorderedAspectRatio ratio={667 / 1000}>
      <ImageWrapper>
        <CaptionWrapper captionKind={captionKind}>
          <CaptionText size="extraSmall">
            <div>{ICON_FOR_CAPTION_KIND[captionKind]}</div>
            <div>{captionText}</div>
          </CaptionText>
        </CaptionWrapper>
        <MainImage src={imagePath} alt="" />
      </ImageWrapper>
    </BorderedAspectRatio>
  );
};
