import * as React from 'react';

import { IGuideImage } from '../guide_image';
import { IGuideStepProps } from '../types/guide_step';

import { ImageStep } from './image_step';

import organizeBags from '@images/www/guide/good_bad/good/organize_bags.png';
import organizeBoxes from '@images/www/guide/good_bad/good/organize_boxes.png';
import organizeLuggage from '@images/www/guide/good_bad/good/organize_luggage.png';
import organizePiles from '@images/www/guide/good_bad/good/organize_piles.png';

const ORGANIZE_STEP_IMAGES: IGuideImage[] = [
  {
    captionText: 'Put items in any kind of bag',
    captionKind: 'Good',
    imagePath: organizeBags,
  },
  {
    captionText: 'Stack in neat piles',
    captionKind: 'Good',
    imagePath: organizePiles,
  },
  {
    captionText: 'Put items in boxes or bins',
    captionKind: 'Good',
    imagePath: organizeBoxes,
  },
  {
    captionText: 'Pack inside luggage',
    captionKind: 'Good',
    imagePath: organizeLuggage,
  },
];

const ORGANIZE_STEP_TITLE =
  'Organize your items into bags, boxes, luggage, or neat piles';
const ORGANIZE_STEP_DETAILS =
  'Once you have logical groups of items, place them in boxes, bags, or luggage. If you run out, organize in neat piles. ';

export const OrganizeStep: React.FC<IGuideStepProps> = (props) => (
  <ImageStep
    guideImages={ORGANIZE_STEP_IMAGES}
    title={ORGANIZE_STEP_TITLE}
    details={ORGANIZE_STEP_DETAILS}
    {...props}
  />
);
