/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { COLORS, FontWeight, SkeletonLoader, Text } from '@clutter/clean';
import {
  Help__Article,
  Help__Category,
  Help__Section,
} from '@root/graphql/landing';
import { Pluralize } from '@shared/formatters/pluralize';
import { SKIP_SCROLL_TO_TOP_STATE } from '@shared/scroll_to_top';

import { sectionURL } from '../routes';
import { Repeat } from '../utils/repeat';

const Container = styled.div`
  margin: 4px 0;
`;

const SectionTitle = styled(Text.Body)`
  color: ${COLORS.tealDark};
  font-weight: ${FontWeight.Bold};
  margin: 0 0 8px;
`;

const SectionCount = styled(Text.Body)`
  color: ${COLORS.hippo};
  font-weight: ${FontWeight.Regular};
  margin: 8px 0 0;
`;

const SectionContent = styled.div`
  padding: 16px 8px;
`;

const SectionContainer = styled.div`
  padding: 4px 0;

  border-top: 1px solid ${COLORS.grayBorder};

  &:last-of-type {
    border-bottom: 1px solid ${COLORS.grayBorder};
  }
`;

const SectionLink = styled(SectionContainer)`
  display: block;

  &.active > ${SectionContent} {
    background: ${COLORS.tealBackground};
  }

  &:not(.active):hover ${SectionTitle} {
    color: ${COLORS.tealPrimary};
  }
`.withComponent(NavLink);

const Section: React.FC<{
  category: Pick<Help__Category, 'id'>;
  section: Pick<Help__Section, 'id' | 'name'> & {
    articles: ReadonlyArray<Pick<Help__Article, 'id'>>;
  };
}> = ({ category, section }) => (
  <SectionLink
    to={{
      pathname: sectionURL(category.id, section.id),
      state: SKIP_SCROLL_TO_TOP_STATE,
    }}
  >
    <SectionContent>
      <SectionTitle>{section.name}</SectionTitle>
      <SectionCount>
        <Pluralize
          count={section.articles.length}
          singular="Article"
          plural="Articles"
        />
      </SectionCount>
    </SectionContent>
  </SectionLink>
);

const FakeSection: React.FC = () => (
  <SectionContainer>
    <SectionContent>
      <SectionTitle>
        <SkeletonLoader width="100%" height="24px" />
      </SectionTitle>
      <SectionCount>
        <SkeletonLoader width="100%" height="24px" />
      </SectionCount>
    </SectionContent>
  </SectionContainer>
);

export const Sections: React.FC<{
  category?: Pick<Help__Category, 'id' | 'name'> & {
    sections: ReadonlyArray<
      Pick<Help__Section, 'id' | 'name'> & {
        articles: ReadonlyArray<Pick<Help__Article, 'id'>>;
      }
    >;
  };
}> = ({ category }) => (
  <Container>
    {category ? (
      category.sections.map((section) => (
        <Section key={section.id} category={category} section={section} />
      ))
    ) : (
      <Repeat component={FakeSection} times={3} />
    )}
  </Container>
);
