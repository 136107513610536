import styled from '@emotion/styled';

type Direction = 'right' | 'down' | 'left' | 'up';

const DEGREES_FROM_DIRECTION: Record<Direction, string> = {
  right: '45deg',
  down: '135deg',
  left: '225deg',
  up: '315deg',
};

export const Chevron = styled.span<{ direction: Direction }>`
  margin: 0 8px;
  border-style: solid;
  border-width: 2px 2px 0 0;
  position: relative;
  transform: rotate(${({ direction }) => DEGREES_FROM_DIRECTION[direction]});
  vertical-align: middle;
  height: 8px;
  width: 8px;
`;
