import React from 'react';

import { COLORS } from '@clutter/clean';
import { WWW_ROUTES } from '@root/root/routes';
import { ServiceCards } from '@shared/page_builder/components/service_cards';

import localMoving from '@images_responsive/home/mover-in-truck.jpg';
import ssFacilityExterior from '@images_responsive/home/ss-facility-exterior.jpg';
import storageMoving from '@images_responsive/home/storage_moving_solution.jpg';

const CARDS: React.ComponentProps<typeof ServiceCards>['cards'] = [
  {
    img: ssFacilityExterior,
    imgAlt: 'Storage facility services',
    title: 'Storage only',
    description: `The smartest and easiest storage ever. We’ll pick up items from your home, and deliver them when you’re ready.`,
    promo: 'Affordable pickups and deliveries',
    href: { location: WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE },
  },
  {
    img: storageMoving,
    imgAlt: 'Affordable Storage and Moving Services',
    title: 'Storage & moving',
    description:
      'Clutter is your one stop shop for moving and storage, with great service and an even better value.',
    promo: 'Discounts available',
    href: { location: WWW_ROUTES.SERVICE_STORAGE_AND_MOVING },
  },
  {
    img: localMoving,
    imgAlt: 'Clutter Local Moving Services',
    title: 'Local moving',
    description:
      'We make moving hassle free. Clutter handles your in town moves—packing and transportation included!',
    promo: 'Instant quote online',
    href: { location: WWW_ROUTES.SERVICE_MOVING },
  },
];

export const Solutions: React.FC = () => (
  <ServiceCards
    background={COLORS.grayBackground}
    title="Customizable solutions for everyone"
    cards={CARDS}
    cta={{ container: 'solutions', label: 'Get a Quote' }}
  />
);
