/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { mq, ResponsiveContainer, SkeletonLoader, Text } from '@clutter/clean';
import {
  Help__Category,
  Help__Section,
  useHelpCategoriesQuery,
} from '@graphql/landing';

import { assetURL, categoryURL } from '../routes';
import { Repeat } from '../utils/repeat';
import { ResponsiveProp } from '../utils/responsive_props';

import { Sections } from './sections';

const Container = styled.section<{ display: ResponsiveProp<'display'> }>`
  ${mq({ margin: ['48px -24px', '48px 0'] })}

  ${({ display }) => mq({ display })}
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${mq({
    margin: [null, null, '-24px'],
    flexDirection: ['column', null, 'row'],
  })}
`;

const CategoryContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  ${mq({
    padding: ['0 24px', '0', '24px'],
    margin: ['0 0 24px', null, '0'],
  })}
`;

const CategoryIcon = styled.img`
  height: 80px;
  width: auto;

  ${mq({
    display: ['none', null, 'inline'],
  })}
`;

const CategoryTitle = styled.div`
  margin: 16px 0 0;
`;

const Category: React.FC<{
  category: Pick<Help__Category, 'id' | 'name'> & {
    sections: ReadonlyArray<Pick<Help__Section, 'id' | 'name'>>;
  };
}> = ({ category }) => (
  <CategoryContainer>
    <Link to={categoryURL(category.id)}>
      <CategoryIcon
        alt={category.name}
        src={assetURL(`categories/${category.id}.svg`)}
      />
      <CategoryTitle>
        <Text.Title size="extraSmall">{category.name}</Text.Title>
      </CategoryTitle>
    </Link>
    <Sections category={category} />
  </CategoryContainer>
);

const FakeCategory: React.FC = () => (
  <CategoryContainer>
    <CategoryTitle>
      <SkeletonLoader width="100%" height="33px" />
    </CategoryTitle>
    <Sections />
  </CategoryContainer>
);

export const FooterCategories: React.FC<{
  display?: ResponsiveProp<'display'>;
}> = ({ display = 'block' }) => {
  const { data } = useHelpCategoriesQuery();
  const categories = data?.categories;

  return (
    <ResponsiveContainer>
      <Container display={display}>
        <List>
          {categories ? (
            categories.map((category) => (
              <Category key={category.id} category={category} />
            ))
          ) : (
            <Repeat times={4} component={FakeCategory} />
          )}
        </List>
      </Container>
    </ResponsiveContainer>
  );
};
