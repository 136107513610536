import * as React from 'react';

import { IGuideImage } from '../guide_image';
import { IGuideStepProps } from '../types/guide_step';

import { ImageStep } from './image_step';

import badGroup from '@images/www/guide/good_bad/bad/group_items.png';
import goodGroup from '@images/www/guide/good_bad/good/group_items.png';

const GROUP_STEP_IMAGES: IGuideImage[] = [
  {
    captionText: 'Sort items into related groups',
    captionKind: 'Good',
    imagePath: goodGroup,
  },
  {
    captionText: 'Don’t pile everything together',
    captionKind: 'Bad',
    imagePath: badGroup,
  },
];

const GROUP_STEP_TITLE = 'Sort items into groups that go together';
const GROUP_STEP_DETAILS =
  'Put like things with like. Hammers go with tools. T-shirts go with other clothes.';

export const GroupStep: React.FC<IGuideStepProps> = (props) => (
  <ImageStep
    guideImages={GROUP_STEP_IMAGES}
    title={GROUP_STEP_TITLE}
    details={GROUP_STEP_DETAILS}
    {...props}
  />
);
