import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import { Location__Service, useLocationLinksQuery } from '@graphql/platform';
import { LocationLinks } from '@root/components/www/home/location_footer/location_links';
import { ButtonCTA } from '@shared/button_cta';

const Stats: React.FC = () => {
  return (
    <Box.Flex flexDirection="column" gap="24px">
      <Box.FlexItem>
        <Text.Title size="small">6,500 cities</Text.Title>
        <Text.Callout>Clutter is nationwide in USA & Canada</Text.Callout>
      </Box.FlexItem>
      <Box.FlexItem>
        <Text.Title size="small">208,798 customers</Text.Title>
        <Text.Callout>Clutter is storing & moving America</Text.Callout>
      </Box.FlexItem>
      <Box.FlexItem>
        <Text.Title size="small">2,000,000 sqft of storage space</Text.Title>
        <Text.Callout>Clutter has you covered</Text.Callout>
      </Box.FlexItem>
    </Box.Flex>
  );
};

export const LocationFooter: React.FC = () => {
  const { data } = useLocationLinksQuery();

  const states = data?.states ?? [];

  return (
    <Box background={COLORS.moss} color={COLORS.cloud}>
      <Box.Flex
        margin="0 auto"
        maxWidth="1222px"
        flexDirection={['column', null, 'row']}
        padding={['24px 16px', '80px 16px']}
        textAlign={['center', null, 'left']}
        justifyContent="center"
        gap="64px"
      >
        <Box.Flex
          flexBasis="50%"
          flexDirection="column"
          alignItems={['center', null, 'initial']}
        >
          <Text.Title size="medium">Store & Move With Us Nationwide</Text.Title>
          <Box maxWidth="296px" margin="32px 0 0" width="100%">
            <ButtonCTA
              container="locations_footer"
              label="Get Pricing"
              entryPoint="location_footer_cta"
            />
          </Box>
          <Box margin="64px 0 0">
            <Stats />
          </Box>
        </Box.Flex>
        <Box.Flex
          flexBasis="50%"
          flexDirection={['column', null, 'row']}
          justifyContent="space-between"
          gap="32px"
        >
          <Box.FlexItem>
            <LocationLinks
              name="Smart Storage"
              states={states}
              service={Location__Service.SmartStorage}
            />
          </Box.FlexItem>
          <Box.FlexItem>
            <LocationLinks
              name="Local Moving"
              states={states}
              service={Location__Service.Moving}
            />
          </Box.FlexItem>
        </Box.Flex>
      </Box.Flex>
    </Box>
  );
};
