import React from 'react';

import { Testimonial } from '@root/components/www/shared/testimonial_carousel';

import aly from '@images/www/careers/people/aly.jpg';
import brian from '@images/www/careers/people/brian.jpg';
import emily from '@images/www/careers/people/emily.jpg';
import kofo from '@images/www/careers/people/kofo.jpg';
import kyle from '@images/www/careers/people/kyle.jpg';
import leslie from '@images/www/careers/people/leslie.jpg';
import melanie from '@images/www/careers/people/melanie.jpg';
import tim from '@images/www/careers/people/tim.jpg';

export const TESTIMONIALS: Testimonial[] = [
  {
    image: {
      url: emily,
      alt: 'Emily Uhland',
    },
    tagline: (
      <>
        Emily Uhland
        <br /> Head of CX Operations
      </>
    ),
    quote: (
      <>
        Clutter hires bright people and gives them the autonomy to solve
        challenging problems. The services that we offer have a very real impact
        on customers’ everyday lives and each member of the team plays a
        critical role in helping them during times of transition.
      </>
    ),
    location: 'Based in Santa Barbara, CA',
  },
  {
    image: {
      url: leslie,
      alt: 'Leslie Ashmeade',
    },
    tagline: (
      <>
        Leslie Ashmeade
        <br /> Software Engineer IV
      </>
    ),
    quote: (
      <>
        Clutter provides a unique service to customers and oftentimes new and
        exciting challenges present themselves. I love having the opportunity to
        face these challenges head on with my team to create a better experience
        for both customers and employees.
      </>
    ),
    location: 'Based in Canada',
  },
  {
    image: {
      url: tim,
      alt: 'Tim Lauria',
    },
    tagline: (
      <>
        Tim Lauria
        <br /> Sr. Operations Manager - Field
      </>
    ),
    quote: (
      <>
        My favorite part of working for Clutter is the camaraderie and
        relationships that I’ve built, as well as the knowledge that my voice is
        always heard. Working for a growing company, you have a direct impact on
        being part of a team who develops operations and finds the best
        processes to put in place. Clutter is the best company that I’ve ever
        worked for!
      </>
    ),
    location: 'Based in Middlesex, NJ',
  },
  {
    image: {
      url: brian,
      alt: 'Brian Rodriguez',
    },
    tagline: (
      <>
        Brian Rodriguez
        <br /> Sr. Lead
      </>
    ),
    quote: (
      <>
        The biggest reason I like working for Clutter is the people. The
        teammates that you work with are a huge plus as we build camaraderie
        amongst each other. Knowing that we can impact someone’s life in a
        positive way is an added bonus.
      </>
    ),
    location: 'Based in Moonachie, NJ',
  },
  {
    image: {
      url: melanie,
      alt: 'Melanie Jaramillo',
    },
    tagline: (
      <>
        Melanie Jaramillo
        <br /> Payroll Specialist
      </>
    ),
    quote: (
      <>
        My favorite part about working at Clutter has been the encouragement and
        patience I have received when learning something new. My team has been
        great at encouraging me to learn new skills and they have really helped
        me develop professionally.
      </>
    ),
    location: 'Based in Carson, CA',
  },
  {
    image: {
      url: aly,
      alt: 'Aly Hirschhorn',
    },
    tagline: (
      <>
        Aly Hirschhorn
        <br />
        Product Analyst
      </>
    ),
    quote: (
      <>
        If you also get excited about logistics problems, Clutter is the place
        to be! It’s been great to be able to work on projects ranging from
        operations, customer experience, and pricing and see your work actually
        make an impact on customers.
      </>
    ),
    location: 'Based in New York City, NY',
  },
  {
    image: {
      url: kofo,
      alt: 'Kofo Adafin',
    },
    tagline: (
      <>
        Kofo Adafin
        <br /> Regional Manager
      </>
    ),
    quote: (
      <>
        My favorite part of working at Clutter is the extremely intelligent,
        talented, and motivated team members I work with across every role in
        the organization. We are empowered and encouraged to improve processes
        that make the employee and customer experience better.
      </>
    ),
    location: 'Based in Fontana, CA',
  },
  {
    image: {
      url: kyle,
      alt: 'Kyle Tepe',
    },
    tagline: (
      <>
        Kyle Tepe
        <br /> NE Warehouse Ops Manager
      </>
    ),
    quote: (
      <>
        My favorite part of working at Clutter is how amazing the team is across
        the board and how every single day brings a new challenge. The vision of
        Clutter truly fits perfectly to make the customer’s life more
        convenient. Everyone who I have worked with at Clutter has the same goal
        in mind which is Success.
      </>
    ),
    location: 'Based in Monroe, NJ',
  },
];
