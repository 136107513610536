/* @jsx jsx */
import * as React from 'react';

import { css, jsx } from '@emotion/react';

import { mq } from '@clutter/clean';

import { Panel } from '../shared/panel';

const styles = css`
  ${mq({
    padding: ['70px 24px', '0 48px'],
  })}
`;

const CompanyPanel: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => <Panel css={styles}>{children}</Panel>;

export { CompanyPanel };
