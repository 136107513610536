import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';

import { getPropValue } from '@clutter/clean';

import { useStabilizedFunction } from '../../utilities/hooks';

const DRAG_DELTA_THRESHOLD = 0.2; // 20%

const Slide = styled.div<{ numSlides: number }>`
  flex-shrink: 0;
  flex-basis: calc(100% / ${getPropValue('numSlides')});
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  left: 0;
  position: relative;

  & * {
    user-select: none;
  }
`;

const Slider = styled(animated.div)`
  display: flex;
  user-select: none;
  touch-action: pan-y;
`;

const useSlides = (
  children: React.ReactNode[],
  slidesToShow: number,
  offset: number,
  wrapAround: boolean,
) =>
  useMemo(() => {
    const numSlides = React.Children.count(children);
    const numResolvedSlides = numSlides + slidesToShow * 2;
    const slides = React.Children.map(children, (child) => (
      <Slide numSlides={numResolvedSlides}>{child}</Slide>
    ))!;

    const rotatedSlides =
      offset === 0
        ? slides
        : slides.slice(-offset).concat(slides.slice(0, -offset));

    const leadingSlides = rotatedSlides.slice(0, slidesToShow);
    const tailingSlides = rotatedSlides.slice(numSlides - slidesToShow);
    const allSlides = wrapAround
      ? [tailingSlides, rotatedSlides, leadingSlides]
      : rotatedSlides;
    const totalNumSlides = numSlides + slidesToShow * 2;

    return [numSlides, allSlides, totalNumSlides] as const;
  }, [children, slidesToShow, offset, wrapAround]);

const getTranslate = (
  xOffset: number,
  numSlides: number,
  totalSlides: number,
  slidesToShow: number,
  wrapAround = false,
) => {
  const wrappedIndex = wrapAround ? (xOffset + numSlides) % numSlides : xOffset;
  return wrapAround
    ? // When slides wrap, we add `slidesToShow` slides are prefixed to the start and must be offset
      (-100 / totalSlides) * (wrappedIndex + slidesToShow)
    : (-100 / totalSlides) * wrappedIndex;
};

interface ICarouselState {
  // options
  wrapAround: boolean;
  draggable: boolean;

  // state
  idx: number;
  dragging: boolean;
  dragDeltaRef: React.MutableRefObject<number>;
  updaters: Set<any>;
  resettingRef: React.MutableRefObject<boolean>;

  // setters
  setIdx: (idx: number | ((current: number) => number)) => void;
  next: () => void;
  prev: () => void;
  triggerUpdate: () => void;
  setDragging: (dragging: boolean) => void;
  setUpdaters: (updateFn: (current: Set<Updater>) => Set<Updater>) => void;
}

type Updater = () => void;

export function useCarousel({
  initialIndex = 0,
  draggable = true,
  wrapAround = true,
}: {
  initialIndex?: number | (() => number);
  transitionDuration?: number;
  transitionDelay?: number;
  draggable?: boolean;
  wrapAround?: boolean;
} = {}): ICarouselState {
  const [idx, setIdx] = useState(initialIndex);
  const [dragging, setDragging] = useState(false);
  const dragDeltaRef = useRef(0);
  const resettingRef = useRef(false);
  const [updaters, setUpdaters] = useState(() => new Set<Updater>());

  const next = useCallback(() => {
    setIdx((idx) => idx + 1);
  }, [setIdx]);

  const prev = useCallback(() => {
    setIdx((idx) => idx - 1);
  }, [setIdx]);

  const triggerUpdate = useCallback(() => {
    updaters.forEach((u) => u());
  }, [updaters]);

  return {
    dragDeltaRef,
    draggable,
    dragging,
    idx,
    next,
    prev,
    setDragging,
    setIdx: (arg) => {
      setIdx(arg);
    },
    wrapAround,
    updaters,
    setUpdaters,
    triggerUpdate,
    resettingRef,
  };
}

type CarouselProps = {
  children: React.ReactNode[];
  slidesToShow: number;
  className?: string;
  /** Shifts slides to the right. Useful for centering a particular slide if multiple slides are shown at once */
  offset?: number;
} & ICarouselState;

export function Carousel({
  children,
  slidesToShow,
  idx,
  setIdx,
  dragging,
  setDragging,
  className,
  offset = 0.2,
  dragDeltaRef,
  wrapAround = true,
  setUpdaters,
  triggerUpdate,
  resettingRef,
  draggable,
}: CarouselProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef(0);
  const localDraggingRef = useRef(false);
  const [numSlides, combinedSlides, totalNumSlides] = useSlides(
    children,
    slidesToShow,
    offset,
    wrapAround,
  );

  // Provide update function to parent
  const update = useStabilizedFunction((immediate = false) => {
    const animatedIndex = idx + dragDeltaRef.current;

    set({
      animatedIndex,
      immediate,
    });
  });

  useEffect(() => {
    setUpdaters((current) => {
      const next = new Set(current);
      next.add(update);
      return next;
    });
    return () => {
      setUpdaters((current) => {
        const next = new Set(current);
        next.delete(update);
        return next;
      });
    };
  }, [setUpdaters, update]);

  // Ensure the index is within bounds when a rest state is reached.
  const onRest = useStabilizedFunction(() => {
    if (idx < 0 || idx >= numSlides) {
      resettingRef.current = true;
      setIdx((idx + numSlides) % numSlides);
    }
  });

  const [style, set] = useSpring(() => ({
    animatedIndex: idx,
    onRest,
  }));

  // Update horizontal position on index change
  useEffect(() => {
    set({
      animatedIndex: idx,
      immediate: resettingRef.current,
    });
    // If multiple carousels are sharing state, they often trigger effects
    // around the same time when a transition ends. Clear the `resetting`
    // flag on next tick to ensure it can be read by all effects if needed.
    setTimeout(() => {
      resettingRef.current = false;
    });
  }, [totalNumSlides, idx, slidesToShow, wrapAround, set, resettingRef]);

  // Event listeners
  useEffect(() => {
    const endListener = () => {
      if (!localDraggingRef.current) return;
      const dragDelta = dragDeltaRef.current;
      if (Math.abs(dragDelta) > DRAG_DELTA_THRESHOLD) {
        const nextIdx = idx + (dragDelta > DRAG_DELTA_THRESHOLD ? 1 : -1);
        const canNext =
          wrapAround || (nextIdx >= 0 && nextIdx <= numSlides - slidesToShow);
        if (canNext) {
          setIdx(nextIdx);
        } else {
          dragDeltaRef.current = 0;
          triggerUpdate();
        }
      } else {
        triggerUpdate();
      }
      setDragging(false);
      dragDeltaRef.current = 0;
      localDraggingRef.current = false;
    };
    const updateListener = (e: PointerEvent) => {
      if (!localDraggingRef.current) return;
      dragDeltaRef.current =
        e.clientX === 0
          ? 0
          : (startXRef.current - e.clientX) /
            containerRef.current!.getBoundingClientRect().width;
      triggerUpdate();
    };
    window.addEventListener('pointermove', updateListener);
    window.addEventListener('pointerup', endListener);
    window.addEventListener('pointerleave', endListener);
    return () => {
      window.removeEventListener('pointermove', updateListener);
      window.removeEventListener('pointerup', endListener);
      window.removeEventListener('pointerleave', endListener);
    };
  }, [
    setDragging,
    dragDeltaRef,
    dragging,
    idx,
    setIdx,
    triggerUpdate,
    numSlides,
    slidesToShow,
    wrapAround,
  ]);

  const sliderWidth = `${((totalNumSlides / slidesToShow) * 100).toFixed(6)}%`;

  return (
    <Container ref={containerRef} className={className}>
      <Slider
        style={{
          width: sliderWidth,
          cursor: dragging ? 'grabbing' : undefined,
          transform: style.animatedIndex.interpolate(
            (val) =>
              `translateX(${getTranslate(
                val as number,
                numSlides,
                totalNumSlides,
                slidesToShow,
                wrapAround,
              )}%`,
          ),
        }}
        onPointerDown={(e) => {
          if (!draggable) return;
          startXRef.current = e.clientX;
          setDragging(true);
          localDraggingRef.current = true;
        }}
      >
        {combinedSlides}
      </Slider>
    </Container>
  );
}
