/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';

import { Footer as SharedFooter } from '@shared/content/footer';

export const Footer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <footer>
    {children}
    <SharedFooter />
  </footer>
);
