import React from 'react';

import {
  DOOR_TO_DOOR_FAQ_CONTENT,
  MOVING_FAQ_CONTENT,
} from '@root/constants/faq';

import { FAQCategories } from './faq_categories';

const FAQ: React.FC<{}> = () => {
  const categories = [
    {
      title: 'Smart Storage',
      questions: DOOR_TO_DOOR_FAQ_CONTENT,
    },
    {
      title: 'Moving',
      questions: MOVING_FAQ_CONTENT,
    },
  ];

  const headline = (
    <>
      A fresh take on
      <br />
      storage & moving
    </>
  );

  return <FAQCategories headline={headline} categories={categories} />;
};

export { FAQ };
