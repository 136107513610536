import * as React from 'react';

import styled from '@emotion/styled';

import {
  BREAKPOINTS,
  COLORS,
  FontWeight,
  mq,
  Text,
  toggleStyleValue,
} from '@clutter/clean';
import { WwwOneFunnelEntryPoint } from '@root/resources/lead';
import { ResponsiveImage } from '@shared/responsive_image';
import { Spacer } from '@shared/spacer';

import { ButtonCTA } from '../../shared/button_cta';

const Container = styled.div<{ reversed?: boolean }>`
  color: ${COLORS.tealDark};
  margin: 0 auto;
  ${mq({
    padding: ['64px 24px', null, '64px 0 84px 120px', '100px 0 84px 184px'],
    maxWidth: [BREAKPOINTS.SM, BREAKPOINTS.MD, BREAKPOINTS.LG, BREAKPOINTS.XL],
    textAlign: ['center', null, 'left'],
  })};
`;

const Subtitle = styled(Text.Title)`
  margin: 8px 0 40px;
`;

const Headline = styled(Text.Headline)`
  margin-bottom: 4px;
`;

const Feature = styled.div`
  margin: 0 0 24px;

  &:last-child {
    margin: 0;
  }
`;

const ContentContainer = styled.div<{ reversed?: boolean }>`
  display: flex;

  ${mq({
    margin: ['-20px', null, '-60px'],
  })}

  > div {
    ${mq({
      padding: ['20px', null, '60px'],
    })}
  }

  ${({ reversed }) =>
    mq({
      flexDirection: ['column', null, reversed ? 'row-reverse' : 'row'],
    })}
`;

const TextContainer = styled.div<{ reversed?: boolean }>`
  ${({ reversed }) =>
    mq({
      order: [1, null, 0],
      width: [null, null, '540px'],
      marginRight: [null, null, reversed ? '24px' : 'initial'],
    })}
  flex-shrink: 0;
  flex-grow: 0;
`;

const ImageContainer = styled.div<{ reversed?: boolean }>`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mq({
    order: [0, null, 1],
    margin: ['-8px', null, '0px -12px'],
    justifyContent: ['center', null, 'flex-start'],
  })}
  flex-direction: ${toggleStyleValue('reversed', 'row-reverse', 'row')};
`;

export const ServiceImage = styled(ResponsiveImage)`
  display: block;
  border-radius: 8px;
  height: auto;
  ${mq({
    margin: ['8px', null, '12px'],
  })}
`;

export const ServiceInfo: React.FC<{
  title: string;
  subtitle: string;
  features: Array<{
    name: React.ReactNode;
    summary: React.ReactNode;
  }>;
  images: React.ReactNode;
  cta: {
    offeringType?: string;
    objectType?: string;
    objectName?: string;
    entryPoint: WwwOneFunnelEntryPoint;
  };
  reversed?: boolean;
}> = ({ title, subtitle, features, images, cta, reversed }) => (
  <div>
    <Container reversed={reversed}>
      <ContentContainer reversed={reversed}>
        <ImageContainer reversed={reversed}>{images}</ImageContainer>
        <TextContainer reversed={reversed}>
          <Text.Title size="large">{title}</Text.Title>
          <Subtitle size="extraSmall">{subtitle}</Subtitle>
          {features.map(({ name, summary }, key) => (
            <Feature key={key}>
              <Headline weight={FontWeight.Medium}>{name}</Headline>
              <Text.Body>{summary}</Text.Body>
            </Feature>
          ))}
          <Spacer height="40px" />
          <ButtonCTA
            container="product_offering"
            label="Get a Quote"
            {...cta}
          />
        </TextContainer>
      </ContentContainer>
    </Container>
  </div>
);
