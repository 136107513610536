import * as React from 'react';

import styled from '@emotion/styled';

import { Box, BREAKPOINTS, COLORS, mq, Text } from '@clutter/clean';
import { ZipCTA } from '@root/components/www/home/zip_cta';
import { ResponsiveDisplay } from '@shared/formatters/responsive_display';
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  ZIP_INPUT_TRIGGER_ID,
} from '@shared/header/header';
import { PinnedFooterContainer } from '@shared/pinned_footer_container';
import { ResponsiveImage } from '@shared/responsive_image';
import { ServiceSelection } from '@shared/service_selector/constants';
import { useIntersectionObserver } from '@utils/hooks';
import { IResponsiveImage } from '@utils/images';

import sparkle from '@images/illustrations/sparkle_large.svg';
import facility from '@images/illustrations/storage_facility.svg';

const HERO_ZIP_INPUT_ID = 'hero_zip_input';

const Container = styled.div`
  color: ${COLORS.tealDark};
  overflow: hidden;

  ${mq({
    textAlign: ['center', null, 'left'],
    marginTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
    paddingTop: ['64px', null, 0],
  })}
`;

const FluidContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1600px;

  ${mq({
    flexDirection: ['column', null, 'row'],
    padding: ['0 24px', null, '60px'],
  })}
`;

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainer = styled.div`
  width: 100%;

  ${mq({
    maxWidth: ['100%', '80%', '45%'],
    margin: ['0 auto 24px', null, 'auto 5% auto 0'],
  })};
`;

const PinnedFooter = styled(PinnedFooterContainer)`
  ${mq({
    display: ['flex', 'none'],
  })}
  justify-content: center;
  form {
    width: 100%;
  }
`;

const Title = styled(Text.Display)`
  margin-bottom: 24px;
`;

const Subtitle = styled(Text.Headline)`
  ${mq({ marginBottom: ['32px', null, '48px'] })}
`;

const HeroImage = styled(ResponsiveImage)`
  border-radius: 8px;
  width: 100%;
`;

const PromoSparkle = styled.img`
  position: absolute;
  left: 38px;
  top: -18px;
`;

const PromoBannerContainer = styled.div<{ freeStorage?: boolean }>`
  min-height: 52px;
  background: ${({ freeStorage }) =>
    freeStorage ? COLORS.dust : COLORS.tealBackground};
  color: ${COLORS.panther};
  position: relative;
  padding: 0 24px;
  margin-top: 24px;
  border-radius: 4px;

  ${mq({
    display: ['block', null, 'inline-block'],
  })}
`;

const PromoBannerInner = styled.div`
  min-height: 52px;
  margin: auto;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const StyledBannerIcon = styled.img`
  width: 45px;
  height: 31px;
  margin-right: 20px;
`;

const ProductHero: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  image: IResponsiveImage;
  service: ServiceSelection;
}> = ({ title, subtitle, image, service }) => {
  const [setNode, entry] = useIntersectionObserver();
  const isIntersecting = entry ? entry.isIntersecting : true;

  return (
    <Container ref={setNode}>
      <FluidContainer>
        <ResponsiveDisplay mode="only" sm md>
          <ColumnLayout>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </ColumnLayout>
        </ResponsiveDisplay>
        <ImageContainer>
          <HeroImage
            image={image}
            sizes={`(max-width: ${BREAKPOINTS.MD}) 100vw, 50vw`}
            alt=""
          />
        </ImageContainer>
        <ColumnLayout>
          <ResponsiveDisplay mode="not" sm md>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </ResponsiveDisplay>
          <div
            id={ZIP_INPUT_TRIGGER_ID}
            data-test-id="product_hero_cta_container"
          >
            {service === ServiceSelection.Moving ? (
              <Box.Flex flexDirection="column">
                <Box>
                  <ZipCTA
                    id="hero_zip_cta"
                    wtConfig={{ container: 'product_hero', service }}
                    service={service}
                    inputId={HERO_ZIP_INPUT_ID}
                  />
                </Box>
                <PromoBannerContainer freeStorage={true}>
                  <PromoBannerInner>
                    <Box.Flex flexShrink={0} position="relative">
                      <StyledBannerIcon
                        src={facility}
                        alt="Clutter facility icon"
                      />
                      <PromoSparkle src={sparkle} alt="" role="none" />
                    </Box.Flex>
                    <Text.Caption>
                      <b>
                        <>
                          1 month of free storage with moving! Redeem at
                          checkout.
                        </>
                      </b>
                    </Text.Caption>
                  </PromoBannerInner>
                </PromoBannerContainer>
              </Box.Flex>
            ) : (
              <ZipCTA
                id="hero_zip_cta"
                wtConfig={{ container: 'product_hero', service }}
                service={service}
                inputId={HERO_ZIP_INPUT_ID}
              />
            )}
          </div>
        </ColumnLayout>
        <PinnedFooter show={!isIntersecting} hideMobile={true}>
          <ZipCTA
            id="footer_zip_cta"
            wtConfig={{ container: 'pin_zip', service }}
            wrap={false}
            inputPlaceholder="Enter your ZIP"
            service={service}
          />
        </PinnedFooter>
      </FluidContainer>
    </Container>
  );
};

export { ProductHero };
