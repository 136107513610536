import React, { type ReactNode } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';
import { WwwOneFunnelEntryPoint } from '@root/resources/lead';
import { AspectRatio } from '@shared/aspect_ratio';
import { ButtonCTA } from '@shared/button_cta';
import { CustomLink } from '@shared/custom_link';
import { Block, BlockProps } from '@shared/page_builder/block';
import { ResponsiveImage } from '@shared/responsive_image';
import { WtClick } from '@shared/wt_click';
import { IResponsiveImage } from '@utils/images';

type CardData = {
  title: React.ReactNode;
  promo?: React.ReactNode;
  img: IResponsiveImage;
  imgAlt: string;
  description: React.ReactNode;
  href?: { location: string };
};

const CardLink = styled(CustomLink)`
  display: block;
  margin-top: 8px;
`;

const ImageContainer: React.FC<{
  children: ReactNode;
  href?: { location: string; external?: boolean };
}> = ({ href, children }) => {
  return href ? (
    <CustomLink to={href.location} target={href.external ? '_blank' : '_self'}>
      {children}
    </CustomLink>
  ) : (
    <>{children}</>
  );
};

const Solution: React.FC<{ data: CardData }> = ({ data }) => {
  return (
    <Box maxWidth="400px" minWidth="327px" width="100%">
      <WtClick
        params={{
          container: 'solutions',
          objectType: 'image',
          action: 'click',
          objectName: 'solution_card_image',
          value: data.href && `${data.href.location}`,
        }}
      >
        <Box
          borderRadius="8px"
          overflow="hidden"
          background={COLORS.grayBackground}
          position="relative"
        >
          <ImageContainer href={data.href}>
            <AspectRatio ratio={0.65}>
              <ResponsiveImage
                image={data.img}
                sizes="384px"
                loading="lazy"
                alt={data.imgAlt}
                baseImageSizes={[384, 768]}
              />
            </AspectRatio>
            {data.promo && (
              <Box
                position="absolute"
                top="20px"
                left="16px"
                borderRadius="20px"
                padding="8px 14px"
                background={COLORS.dust}
                color={COLORS.tealDark}
              >
                <Text.Body>
                  <b>{data.promo}</b>
                </Text.Body>
              </Box>
            )}
          </ImageContainer>
        </Box>
      </WtClick>
      <Box margin="12px 0 8px">
        <Text.Title size="small">{data.title}</Text.Title>
      </Box>
      <Text.Callout>{data.description}</Text.Callout>
      {data.href && (
        <WtClick
          params={{
            container: 'solutions',
            objectType: 'button',
            action: 'click',
            objectName: 'solution_card_link',
            value: `${data.href.location}`,
          }}
        >
          <CardLink to={data.href.location}>
            <Text.SmallCaps>Learn More</Text.SmallCaps>
          </CardLink>
        </WtClick>
      )}
    </Box>
  );
};

export const ServiceCards: React.FC<
  {
    cards: CardData[];
    title: React.ReactNode;
    cta?: {
      label: string;
      container: string;
      entryPoint?: WwwOneFunnelEntryPoint;
    };
  } & BlockProps
> = ({ title, cards, cta, padding, ...blockProps }) => {
  return (
    <Block padding={['48px 0', '60px 0']} {...blockProps}>
      <Box textAlign="center" margin={['0 0 24px', null, '0 0 48px']}>
        <Text.Title size="large">{title}</Text.Title>
      </Box>
      <Box.Flex
        flexWrap="wrap"
        justifyContent="center"
        gap="32px 16px"
        margin="0 auto"
        maxWidth={cards.length % 2 === 0 ? '820px' : undefined}
      >
        {cards.map((data) => (
          <Solution key={data.img.src} data={data} />
        ))}
      </Box.Flex>
      {cta && (
        <Box margin={['32px 0 0', '48px 0 0']} textAlign="center">
          <ButtonCTA
            container="solutions"
            label="Get a Quote"
            entryPoint="solutions_cta"
          />
        </Box>
      )}
    </Block>
  );
};
