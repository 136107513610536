import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

const LIGHT_BACKGROUND_COLOR = COLORS.cloud;
const DARK_BACKGROUND_COLOR = COLORS.grayBackground;

export const ZebraPattern = {
  LIGHT_THEN_DARK: {
    even: LIGHT_BACKGROUND_COLOR,
    odd: DARK_BACKGROUND_COLOR,
  },
  DARK_THEN_LIGHT: {
    even: DARK_BACKGROUND_COLOR,
    odd: LIGHT_BACKGROUND_COLOR,
  },
};

export const ZebraContainer = styled.div<{
  zebra: {
    even: string; // i.e. '#FFFFFF'
    odd: string; // i.e. '#000000'
  };
}>`
  overflow-x: hidden;

  & > div:nth-of-type(even) {
    background-color: ${({ zebra: { even } }) => even};
  }
  & > div:nth-of-type(odd) {
    background-color: ${({ zebra: { odd } }) => odd};
  }

  // i.e. hero
  & > div:first-of-type {
    background-color: ${COLORS.cloud};
  }
`;
