import * as React from 'react';

import styled from '@emotion/styled';

import { Box, ButtonLink, COLORS, mq, Text } from '@clutter/clean';
import { TestimonialCarousel } from '@root/components/www/shared/testimonial_carousel';
import { Footer } from '@shared/content/footer';
import { ResponsiveImage } from '@shared/responsive_image';

import { Header } from '../shared/header/header';

import { CoreValues } from './careers/core_values';
import { LifeCarousel } from './careers/life_carousel';
import { TESTIMONIALS } from './careers/testimonial_data';

import headerImage from '@images_responsive/www/careers/movers_with_rug.jpg';

export const IRM_JOBS_URL = 'https://ironmountain.jobs';

const TopContainer = styled.div`
  width: 100%;
  background: ${COLORS.tealBackground};

  ${mq({
    height: ['280px', '400px', '682px'],
    marginBottom: ['0', null, '-120px'],
  })}
`;

const HeaderImage = styled(ResponsiveImage)`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  background: ${COLORS.grayBackground};
`;

const Careers: React.FC = () => {
  return (
    <Container>
      <Header opaque />
      <TopContainer>
        <HeaderImage
          alt="Two Clutter employees carrying a rug"
          image={headerImage}
          baseImageSizes={[800, 1200, 1600, 3200]}
          loading="eager"
        />
      </TopContainer>
      <Box
        boxShadow="0 16px 32px 0 rgb(10 61 58 / 10%)"
        background={COLORS.cloud}
        maxWidth="1230px"
        margin="0 auto"
        position="relative"
        padding={['24px', '60px 24px']}
        textAlign="center"
      >
        <Text.Title size="large">Join the Clutter Team!</Text.Title>
        <Box margin="24px 0 0" textAlign="center">
          <Text.Body>
            Clutter was acquired by Iron Mountain on Jun 29, 2023.
          </Text.Body>
          <Text.Body>
            All Clutter jobs can now be found on the Iron Mountain career site.
          </Text.Body>
        </Box>
        <Box margin="24px 0 0">
          <ButtonLink href={IRM_JOBS_URL}>Current Job Openings</ButtonLink>
        </Box>
      </Box>
      <Box padding={['60px 24px', '100px 24px']} textAlign="center">
        <Text.Title size="large">People are our superpower.</Text.Title>
        <TestimonialCarousel testimonials={TESTIMONIALS} />
      </Box>
      <LifeCarousel />
      <CoreValues />
      <Footer />
    </Container>
  );
};

export { Careers };
