import styled from '@emotion/styled';

import { mq } from '@clutter/clean';

const Panel = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  ${mq({
    margin: ['0', '0 auto'],
    marginBottom: ['0', '96px'],
  })}
`;

export { Panel };
